import React from 'react';
import { Grid } from '@material-ui/core';
import { StyledList, StyledListItem, StyledTypography } from '../../styles';

const Disclaimer = () => {
  return (
    <Grid container spacing={2} sx={{ color: 'white' }}>
      <Grid item xs={12} id="disclaimer-section1">
        <StyledTypography className="heading" sx={{ textAlign: 'center' }}>
          Disclaimer
        </StyledTypography>
        <StyledTypography sx={{ textAlign: 'center' }}>
          Updated at October 23rd, 2023
        </StyledTypography>
        <br />
        <br />
        <StyledTypography>
          Divercefi.com hereby grants you access to https://divercefi.com;
          https://app.divercefi.com (&quot;the Website&quot;) and invites you to
          purchase the services offered here.
        </StyledTypography>
      </Grid>
      <Grid item xs={12} id="disclaimer-section2">
        <StyledTypography className="title">
          Definitions and key terms
        </StyledTypography>
        <StyledTypography>
          To help explain things as clearly as possible in this Disclaimer,
          every time any of these terms are referenced, are strictly defined as:
        </StyledTypography>
        <StyledList>
          <StyledListItem>
            <StyledTypography>
              Cookie: small amount of data generated by a website and saved by
              your web browser. It is used to identify your browser, provide
              analytics, remember information about you such as your language
              preference or login information.
            </StyledTypography>
          </StyledListItem>
          <StyledListItem>
            <StyledTypography>
              Company: when this policy mentions &quot;Company,&quot;
              &quot;we,&quot; &quot;us,&quot; or &quot;our,&quot; it refers to
              Cryptoeasy AG, Bösch 37, 6331 Zug that is responsible for your
              information under this Disclaimer.
            </StyledTypography>
          </StyledListItem>
          <StyledListItem>
            <StyledTypography>
              Service: refers to the service provided by Divercefi.com as
              described in the relative terms (if available) and on this
              platform.
            </StyledTypography>
          </StyledListItem>
          <StyledListItem>
            <StyledTypography>
              Website: Divercefi.com&apos;s site, which can be accessed via this
              URL: https://divercefi.com; https://app.divercefi.com
            </StyledTypography>
          </StyledListItem>
          <StyledListItem>
            <StyledTypography>
              You: a person or entity that is registered with Divercefi.com to
              use the Services.
            </StyledTypography>
          </StyledListItem>
        </StyledList>
      </Grid>
      <Grid item xs={12} id="disclaimer-section3">
        <StyledTypography className="title">Limited liability</StyledTypography>
        <StyledTypography>
          Divercefi.com endeavours to update and/or supplement the content of
          the website on a regular basis. Despite our care and attention,
          content may be incomplete and/or incorrect.
          <br />
          <br />
          The materials offered on the website are offered without any form of
          guarantee or claim to their correctness. These materials can be
          changed at any time without prior notice from Divercefi.com.
          <br />
          <br />
          Particularly, all prices on the website are stated subject to typing
          and programming errors. No liability is assumed for the implications
          of such errors. No agreement is concluded on the basis of such errors.
          <br />
          <br />
          Divercefi.com shall not bear any liability for hyperlinks to websites
          or services of third parties included on the website. From our
          website, you can visit other websites by following hyperlinks to such
          external sites. While we strive to provide only quality links to
          useful and ethical websites, we have no control over the content and
          nature of these sites. These links to other websites do not imply a
          recommendation for all the content found on these sites. Site owners
          and content may change without notice and may occur before we have the
          opportunity to remove a link which may have gone &quot;bad&quot;.
          <br />
          <br />
          Please be also aware that when you leave our website, other sites may
          have different privacy policies and terms which are beyond our
          control. Please be sure to check the Privacy Policies of these sites
          as well as their &quot;Terms of Service&quot; before engaging in any
          business or uploading any information.
        </StyledTypography>
      </Grid>
      <Grid item xs={12} id="disclaimer-section4">
        <StyledTypography className="title">
          Links to Other Websites Disclaimer
        </StyledTypography>
        <StyledTypography>
          This Disclaimer applies only to the Services. The Services may contain
          links to other websites not operated or controlled by Divercefi.com.
          We are not responsible for the content, accuracy or opinions expressed
          in such websites, and such websites are not investigated, monitored or
          checked for accuracy or completeness by us. Please remember that when
          you use a link to go from the Services to another website, our Privacy
          Policy is no longer in effect. Your browsing and interaction on any
          other website, including those that have a link on our platform, is
          subject to that website&apos;s own rules and policies. Such third
          parties may use their own cookies or other methods to collect
          information about you. If You click on a third party link, You will be
          directed to that third party&apos;s site. We strongly advise You to
          review the Privacy Policy and Terms of every site You visit.
          <br />
          <br />
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </StyledTypography>
      </Grid>
      <Grid item xs={12} id="disclaimer-section5">
        <StyledTypography className="title">
          Errors and Omissions Disclaimer
        </StyledTypography>
        <StyledTypography>
          Divercefi.com is not responsible for any content, code or any other
          imprecision.
          <br />
          <br />
          Divercefi.com does not provide warranties or guarantees.
          <br />
          <br />
          In no event shall Divercefi.com be liable for any special, direct,
          indirect, consequential, or incidental damages or any damages
          whatsoever, whether in an action of contract, negligence or other
          tort, arising out of or in connection with the use of the Service or
          the contents of the Service. Divercefi.com reserves the right to make
          additions, deletions, or modifications to the contents on the Service
          at any time without prior notice.
        </StyledTypography>
      </Grid>
      <Grid item xs={12} id="disclaimer-section6">
        <StyledTypography className="title">
          General Disclaimer
        </StyledTypography>
        <StyledTypography>
          The Divercefi.com Service and its contents are provided &quot;as
          is&quot; and &quot;as available&quot; without any warranty or
          representations of any kind, whether express or implied. Divercefi.com
          is a distributor and not a publisher of the content supplied by third
          parties; as such, Divercefi.com exercises no editorial control over
          such content and makes no warranty or representation as to the
          accuracy, reliability or currency of any information, content, service
          or merchandise provided through or accessible via the Divercefi.com
          Service. Without limiting the foregoing, Divercefi.com specifically
          disclaims all warranties and representations in any content
          transmitted on or in connection with the Divercefi.com Service or on
          sites that may appear as links on the Divercefi.com Service, or in the
          products provided as a part of, or otherwise in connection with, the
          Divercefi.com Service, including without limitation any warranties of
          merchantability, fitness for a particular purpose or non-infringement
          of third party rights. No oral advice or written information given by
          Divercefi.com or any of its affiliates, employees, officers,
          directors, agents, or the like will create a warranty. Price and
          availability information is subject to change without notice. Without
          limiting the foregoing, Divercefi.com does not warrant that the
          Divercefi.com Service will be uninterrupted, uncorrupted, timely, or
          error-free.
        </StyledTypography>
      </Grid>
      <Grid item xs={12} id="disclaimer-section7">
        <StyledTypography className="title">
          Copyright Disclaimer
        </StyledTypography>
        <StyledTypography>
          All intellectual property rights concerning these materials are vested
          in Divercefi.com. Copying, distribution and any other use of these
          materials is not permitted without the written permission of
          Divercefi.com, except and only to the extent otherwise provided in
          regulations of mandatory law (such as the right to quote), unless
          otherwise stated for certain materials.
        </StyledTypography>
      </Grid>
      <Grid item xs={12} id="disclaimer-section8">
        <StyledTypography className="title">
          Affiliate Links Disclosure
        </StyledTypography>
        <StyledTypography>
          Divercefi.com has affiliate links and in this section of the
          Disclaimer we will address how we use those affiliate links from other
          websites/companies and products. These &quot;affiliate links&quot; are
          specific URLs that contain the affiliate&apos;s ID or username.
          <br />
          <br />
          In compliance with the FTC guidelines, please assume the following
          about links and posts on this site:
        </StyledTypography>
        <StyledList>
          <StyledListItem>
            <StyledTypography>
              Any/all of the links on Divercefi.com are affiliate links of which
              we receive a small commission from sales of certain items, but the
              price is the same for you. As Divercefi.com has grown, so have
              costs associated with running and maintaining it, and affiliate
              links are a way we help offset these costs.
            </StyledTypography>
          </StyledListItem>
          <StyledListItem>
            <StyledTypography>
              If we post an affiliate link to a product, it is something that we
              personally use, support and would recommend without an affiliate
              link.
            </StyledTypography>
          </StyledListItem>
          <StyledListItem>
            <StyledTypography>
              Unless otherwise noted, all reviews are of items we have purchased
              and we are not paid or compensated in any way.
            </StyledTypography>
          </StyledListItem>
        </StyledList>
      </Grid>
      <Grid item xs={12} id="disclaimer-section9">
        <StyledTypography className="title">
          Financial Disclosure
        </StyledTypography>
        <StyledTypography>
          Gross merchandise sales is a non-GAAP metric. We use it to express the
          total demand across all of our web sites and stores. This number
          measures the dollar value of the order placed in the year before
          accruing for certain items such as returns, and it ignores certain
          timing cut-offs that are required by GAAP for revenue recognition
          purposes. If we were a public company, we would have to reconcile
          gross merchandise sales to the nearest GAAP metric (net sales), but we
          are currently a private company so the gross merchandise sales number
          should be viewed just as an interesting number that we want to share
          with our friends.
          <br />
          <br />
          This document contains forward-looking statements that involve risks
          and uncertainties, as well as assumptions that, if they ever
          materialise or prove incorrect, could cause or results to differ
          materially from those expressed or implied by the forward-looking
          statements and assumptions. These risks and uncertainties include, but
          are not limited to, the risk of economic slowdown, the risk of over or
          underbuying, the risk of consumers not shopping online at our website
          at the rate we expected, the risk of supplier shortages, the risk of
          new or growing competition, the risk of natural or some other type of
          disaster affecting our fulfilment of historical operations or web
          servers, and the risk of the world generally coming to an end. All
          statements other than statements historical fact are statements that
          could be deemed forward-looking statements, including statements of
          expectation or belief; and any statement of assumptions underlying any
          of the foregoing. Divercefi.com assumes no obligation and does not
          intend to update these forward-looking statements.
        </StyledTypography>
      </Grid>
      <Grid item xs={12} id="disclaimer-section10">
        <StyledTypography className="title">
          Educational Disclosure
        </StyledTypography>
        <StyledTypography>
          Any Information provided by Divercefi.com is for educational purposes
          only, and is not to be interpreted as a recommendation for a specific
          treatment plan, product, or course of action. Divercefi.com is a
          distributor and not a publisher of the content supplied by third
          parties; as such, Divercefi.com exercises no editorial control over
          such content and makes no warranty or representation as to the
          accuracy, reliability or currency of any information or educational
          content provided through or accessible via Divercefi.com. Without
          limiting the foregoing, Divercefi.com specifically disclaims all
          warranties and representations in any content transmitted on or in
          connection with Divercefi.com or on sites that may appear as links on
          Divercefi.com, or in the products provided as a part of, or otherwise
          in connection with, the Divercefi.com. No oral advice or written
          information given by Divercefi.com or any of its affiliates,
          employees, officers, directors, agents, or the like will create a
          warranty.
        </StyledTypography>
      </Grid>
      <Grid item xs={12} id="disclaimer-section11">
        <StyledTypography className="title">Your Consent</StyledTypography>
        <StyledTypography>
          We&apos;ve updated our Disclaimer to provide you with complete
          transparency into what is being set when you visit our site and how
          it&apos;s being used. By using our website, registering an account, or
          making a purchase, you hereby consent to our Disclaimer and agree to
          its terms.
        </StyledTypography>
      </Grid>
      <Grid item xs={12} id="disclaimer-section12">
        <StyledTypography className="title">
          Changes To Our Disclaimer
        </StyledTypography>
        <StyledTypography>
          Should we update, amend or make any changes to this document so that
          they accurately reflect our Service and policies. Unless otherwise
          required by law, those changes will be prominently posted here. Then,
          if you continue to use the Service, you will be bound by the updated
          Disclaimer. If you do not want to agree to this or any updated
          Disclaimer, you can delete your account.
        </StyledTypography>
      </Grid>
      <Grid item xs={12} id="disclaimer-section13">
        <StyledTypography className="title">Contact Us</StyledTypography>
        <StyledTypography>
          Don&apos;t hesitate to contact us if you have any questions.
        </StyledTypography>
        <StyledList>
          <StyledListItem>
            <StyledTypography>Via Email: hello@divercefi.com</StyledTypography>
          </StyledListItem>
          <StyledListItem>
            <StyledTypography>
              Via this Link: https://divercefi.com/
            </StyledTypography>
          </StyledListItem>
        </StyledList>
      </Grid>
    </Grid>
  );
};

export default Disclaimer;
