import { getExtras } from '../lib/api';

const initialState = {
  isFetching: true,
  fetched: false,
  list: [],
};

export const EXTRAS_LOAD = 'EXTRAS_LOAD';
export const TOGGLE_FETCHING_EXTRAS = 'TOGGLE_FETCHING_EXTRAS';
export const TOGGLE_FETCHED_EXTRAS = 'TOGGLE_FETCHED_EXTRAS';
export const TOGGLE_EXTRA = 'TOGGLE_EXTRA';

export const loadExtras = data => ({ type: EXTRAS_LOAD, payload: data });
export const toggleFetching = value => ({ type: TOGGLE_FETCHING_EXTRAS, value });
export const toggleFetched = value => ({ type: TOGGLE_FETCHED_EXTRAS, value });
export const toggleExtra = id => ({ type: TOGGLE_EXTRA, id });

export const fetchExtras = () => {
  return async (dispatch) => {
    try {
      dispatch(toggleFetching(true));
      const extras = await getExtras();
      dispatch(loadExtras(extras));
      dispatch(toggleFetching(false));
      dispatch(toggleFetched(true));
      return extras;
    } catch (error) {
      return Error('Error fetching and parsing data', error);
    }
  };
};

/* eslint-disable no-confusing-arrow */

const extrasReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXTRAS_LOAD:
      return { ...state, list: action.payload };
    case TOGGLE_FETCHING_EXTRAS:
      return { ...state, isFetching: action.value };
    case TOGGLE_FETCHED_EXTRAS:
      return { ...state, fetched: action.value };
    case TOGGLE_EXTRA:
      return { ...state, list: state.list.map(value => (value.id === action.id) ? { ...value, selected: !value.selected } : value) };
    default:
      return state;
  }
};

export default extrasReducer;
