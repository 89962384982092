import React from 'react';
import { Dialog, Button } from '@material-ui/core';
import { CloseOutlined } from '@ant-design/icons';

import { DialogContent, StyledSlider } from './styles';
import EasyIcon from '../../../../assets/img/Common/logo.svg';
// import Exclamation from '../../../../assets/img/StakingPage/exclamation.svg';
import { withDecimals } from '../../helpers';

const title = ['Harvest Rewards', 'Stake in', 'Unstake from'];
const modalId = { pool1: 'Open', pool2: '7-day', pool3: '30-day', pool4: '90-day' };

const marks = {
  0: '0',
  25: '25%',
  50: '50%',
  75: '75%',
  100: '100%',
};

const Modal = ({
  isOpen,
  onExited,
  handleClose,
  modalType,
  handleChange,
  confirmStake,
  confirmHarvest,
  val,
  stakeValue,
  balance,
  loading,
  priceUSD
}) => {
  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={isOpen}
      onExited={onExited}
      onClose={handleClose}
      className="dialog__staking"
    >
      <DialogContent loading={loading}>
        <div className="dialog__header">
          <p>
            {title[modalType]} 
            {' '}
            {modalId[val.id]}
            {' '}
            Pool
          </p>
          <Button onClick={handleClose} className="close">
            <CloseOutlined />
          </Button>
        </div>
        {modalType === 0 && (
          <div className="dialog__body">
            <div className="harvestVal d-flex">
              <p className="harvestVal__title">Harvest:</p>
              <p className="harvestVal__value">
                <span className="harvestVal__value--easy">
                  {val.harvestValue ? withDecimals(val.harvestValue, 6) : 0}
                  {' '}
                  EASY
                </span>
                <span className="harvestVal__value--usd">
                  ~
                  {' '}
                  {withDecimals(val.harvestValue * priceUSD, 2)}
                  {' '}
                  USD
                </span>
              </p>
            </div>
            <div className="">
              <span
                className="btn btn--blue"
                onClick={confirmHarvest(val.stakedValue, val.id)}
                aria-hidden="true"
              >
                {loading ? 'Loading...' : 'Confirm'}
              </span>
            </div>
            <div className="">
              <span
                className="btn btn--details"
                onClick={handleClose}
                aria-hidden="true"
              >
                Close window
              </span>
            </div>
          </div>
        )}
        {[1, 2].includes(modalType) && (
          <div className="dialog__body">
            <div className="d-flex space-between">
              <p className="">
                {modalType === 1 ? 'Stake' : 'Unstake'}
                :
              </p>
              <div className="logo">
                <img src={EasyIcon} alt="easy" />
                <span>EASY</span>
              </div>
            </div>
            <div className="stakeVal d-flex">
              <p className="stakeVal__value">
                <span className="stakeVal__value--easy">
                  {withDecimals(stakeValue, 6)}
                  {' '}
                  EASY
                </span>
                <span className="stakeVal__value--usd">
                  ~
                  {' '}
                  {withDecimals(stakeValue * priceUSD, 2)}
                  {' '}
                  USD
                </span>
              </p>
            </div>
            {modalType === 1 && (
              <div className="balance d-flex">
                Wallet Balance:
                {' '}
                {withDecimals(balance, 6)}
              </div>
            )}
            {modalType === 2 && (
              <div className="balance d-flex">
                Total Amount Staked: 
                {' '}
                {withDecimals(val.stakedValue, 6)}
              </div>
            )}
            <div className="slider">
              <StyledSlider
                defaultValue={0}
                step={null}
                marks={marks}
                onChange={handleChange}
                tipFormatter={null}
                modalType={modalType}
                // danger={stakeValue > val?.harvestLocked}
                harvestLocked={val.harvestLocked}
              />
            </div>
            <div className="input">
              <label htmlFor="staked_val">
                Staked value:
                <input
                  type="number"
                  pattern="[0-9]*"
                  className="input__val"
                  name="staked_val"
                  id="staked_val"
                  placeholder={withDecimals(stakeValue, 6)}
                  onChange={handleChange}
                  max={balance}
                />
              </label>
            </div>
            <div className="">
              <p className="loadingMsg">{loading || null}</p>
              <span
                className="btn btn--blue"
                aria-hidden="true"
                onClick={confirmStake(stakeValue || val.stakedValue, val.id)}
              >
                {loading ? 'Loading...' : 'Confirm'}
              </span>
            </div>
            {/* {modalType === 1 && val.id !== 'pool1' && (
              <i className="observation">
                By agreeing you engage to lock your funds during the time period of this pool with the possibility to withdraw them earlier with a 3% fee
              </i>
            )}
            {modalType === 2 && stakeValue > val?.harvestLocked && (
              <div className="attention">
                <img src={Exclamation} alt="!" />
                <span>
                  Some or all of the funds are still locked. They will be subjected to a 3% fee if you unlock them before the lock period.
                </span>
              </div>
            )} */}
            <div className="">
              <span
                className="btn btn--details"
                onClick={handleClose}
                aria-hidden="true"
              >
                Close window
              </span>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
