import React from 'react';

import Content from './Content';
import { SolutionsWrapper } from './styles';

const SolutionsSection = (props) => {
  return (
    <SolutionsWrapper id={props.id}>
      <Content imgClickHandler={props.imgClickHandler} />
    </SolutionsWrapper>
  );
};

export default SolutionsSection;
