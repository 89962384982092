/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { OpKind } from '@taquito/taquito';
import { Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import { StakingPageWrapper } from './styles';
import Header from './components/Header';
import Box from './components/Box';
import Modal from './components/Modal';
import { setValue, toggleValue } from '../../reducers/stakingReducer';
import {
  DAppProvider,
  useTezos,
  useAccountPkh,
  useReady,
} from '../../dappstate';
import { SettingsProvider, useSettingsContext } from '../../settings';
import { SnackProvider, useSnackContext } from '../../snackstate';
import Loading from '../../components/Loading';
import {
  projectPool,
  getBalance,
  getActions,
  getHarvest,
  getStaked,
  getPrice,
  getTVL,
  withDecimals,
} from './helpers';
import { GeneralWrapp } from '../../components/Common/GeneralWrapp';
import TopMenu from '../../components/TopMenu';

const StakingContent = (props) => {
  const { staking } = props;
  const ready = useReady();
  const [modalValue, setModalValue] = useState(0);
  const [modalBalance, setModalBalance] = useState(0);
  const [modalStakeValue, setModalStakeValue] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalType, setModalType] = useState();
  const [boxOpen, setBoxOpen] = useState();
  const [loading, setLoading] = useState(false);
  const [priceUSD, setPriceUSD] = useState(0);
  const [nowDate, setNowDate] = useState(false);
  const [totalTVL, setTotalTVL] = useState(0);
  const { setErrorSnack, hideSnack } = useSnackContext();
  const { settings, setEndpoint, setNetwork, setChainid } =
    useSettingsContext();
  const tezos = useTezos();
  const pkh = useAccountPkh();
  const { search } = props.location;
  const params = new URLSearchParams(search);
  const address = params.get('address');
  const token = params.get('token');

  useEffect(() => {
    if (address && token) {
      setNetwork('granadanet');
      setChainid('NetXz969SFaFn8k');
      setEndpoint('https://granadanet.smartpy.io');
    }
  }, [address, token]);

  const actionsAsync = async (i) => {
    const actions = await getActions(
      settings.endpoint,
      settings.chainid,
      address ? address : settings.staking,
      pkh,
      i
    );
    const staked = await getStaked(
      settings.endpoint,
      settings.chainid,
      address ? address : settings.staking,
      pkh,
      i
    );
    const harvest = await getHarvest(
      settings.endpoint,
      settings.chainid,
      address ? address : settings.staking,
      pkh,
      i
    );

    const tvl = await getTVL(
      settings.endpoint,
      settings.chainid,
      address ? address : settings.staking,
      pkh,
      i
    );

    return {
      actions: actions ? actions.data : [],
      stakedValue: staked / 1000000,
      harvestValue: harvest / 1000000,
      tvl: tvl / 1000000,
      enableContract: staked > 0 || harvest > 0 ? true : false,
    };
  };

  useEffect(() => {
    if (pkh) {
      for (let i = 0; i < 4; i += 1) {
        const values = actionsAsync(i);
        values.then((result) => {
          props.setValue({
            id: `pool${i + 1}`,
            ...result,
          });
        });
      }
    }
  }, [setValue, getBalance, getActions, getStaked, settings, pkh]);

  useEffect(() => {
    const tvlArr = staking
      .map((val) => val.tvl)
      .reduce((previousValue, currentValue) => {
        return previousValue + parseFloat(currentValue);
      });
    setTotalTVL(tvlArr);
  }, [staking]);

  useEffect(() => {
    const getPriceC = async () => {
      const usdPrice = await getPrice();
      setPriceUSD(await usdPrice);
    };
    getPriceC();
  }, [getPrice]);

  const getNow = async () => {
    // Staking transaction
    try {
      // const stakingc = await tezos.wallet.at(address ? address : settings.staking);

      // const storage = await stakingc.storage();
      // eslint-disable-next-line dot-notation
      // const now = storage['_now'];
      const now = new Date();
      setNowDate(now);
    } catch (error) {
      // console.error(error);
    }
  };

  useEffect(() => {
    getNow();
  }, [tezos]);

  const handleModalOpen = async (val, sk) => {
    setModalType(val);
    setIsOpenModal(true);
    const balance =
      val === 2
        ? staking?.find((el) => el.id === sk)?.stakedValue
        : (await getBalance(
          settings.endpoint,
          settings.chainid,
          token ? token : settings.token,
          pkh
        )) / 1000000;

    setModalBalance(balance);
    setModalStakeValue(0);
  };

  const handleModalClose = () => {
    setIsOpenModal(false);
    setModalStakeValue(0);
  };

  const handleChange = (val) => {
    switch (val) {
      case 0:
        return setModalStakeValue(0);
      case 25:
        return setModalStakeValue(0.25 * modalBalance);
      case 50:
        return setModalStakeValue(0.5 * modalBalance);
      case 75:
        return setModalStakeValue(0.75 * modalBalance);
      case 100:
        return setModalStakeValue(modalBalance);
      default:
        return setModalStakeValue(val.target.value);
    }
  };

  const handleConfirmStake = (value, stakingKind) => async () => {
    const sk = projectPool(stakingKind);

    try {
      // FA2 approve operator transaction
      const fa2 = await tezos.wallet.at(token ? token : settings.token);
      const fa2approve = fa2.methods
        .update_operators([
          {
            add_operator: {
              owner: pkh,
              operator: address ? address : settings.staking,
              token_id: 0,
            },
          },
        ])
        .toTransferParams();
      fa2approve.kind = OpKind.TRANSACTION;

      // Staking transaction
      const stakingc = await tezos.wallet.at(
        address ? address : settings.staking
      );
      let stake;
      if (modalType === 1) {
        stake = stakingc.methods.stake(value * 1000000, sk).toTransferParams();
      } else if (modalType === 2) {
        stake = stakingc.methods
          .unstake(value * 1000000, sk)
          .toTransferParams();
      }
      stake.kind = OpKind.TRANSACTION;

      // FA2 unapprove operator transaction
      const fa2unapprove = fa2.methods
        .update_operators([
          {
            remove_operator: {
              owner: pkh,
              operator: address ? address : settings.staking,
              token_id: 0,
            },
          },
        ])
        .toTransferParams();
      fa2unapprove.kind = OpKind.TRANSACTION;

      // Group them in a batch operation and send
      let ops = [];
      if (modalType === 1) {
        ops = [fa2approve, stake, fa2unapprove];
      } else if (modalType === 2) {
        ops = [stake];
      }
      const batch = tezos.wallet.batch(ops);
      const operation = await batch.send();
      const shorthash = `${operation.opHash.substring(0, 10)}...`;
      // setInfoSnack(`waiting for ${shorthash} to be confirmed ...`);
      setLoading(`Waiting for ${shorthash} to be confirmed ...`);
      await operation.receipt();

      props.toggleValue({
        id: boxOpen,
      });

      const values = actionsAsync(sk);

      values.then((result) => {
        props.setValue({
          id: stakingKind,
          ...result,
        });
      });

      setLoading(false);
      setIsOpenModal(false);
    } catch (error) {
      // console.error(error);
    }
  };

  const handleConfirmHarvest = (value, stakingKind) => async () => {
    const sk = projectPool(stakingKind);
    try {
      // FA2 approve operator transaction
      // const fa2 = await tezos.wallet.at(token ? token : settings.token);
      // const fa2approve = fa2.methods
      //  .update_operators([
      //    {
      //      add_operator: {
      //        owner: pkh,
      //        operator: address? address:settings.staking,
      //        token_id: 0,
      //      },
      //    },
      //  ])
      //  .toTransferParams();
      // fa2approve.kind = OpKind.TRANSACTION;

      // Staking transaction
      const harvestc = await tezos.wallet.at(
        address ? address : settings.staking
      );
      const harvest = harvestc.methods.harvest(sk).toTransferParams();
      harvest.kind = OpKind.TRANSACTION;

      // FA2 unapprove operator transaction
      // const fa2unapprove = fa2.methods
      //  .update_operators([
      //    {
      //      remove_operator: {
      //        owner: pkh,
      //        operator: address? address:settings.staking,
      //        token_id: 0,
      //      },
      //    },
      //  ])
      //  .toTransferParams();
      // fa2unapprove.kind = OpKind.TRANSACTION;

      // Group them in a batch operation and send
      // const batch = tezos.wallet.batch([fa2approve, harvest, fa2unapprove]);
      const batch = tezos.wallet.batch([harvest]);

      const operation = await batch.send();
      const shorthash = `${operation.opHash.substring(0, 10)}...`;

      setLoading(`Waiting for ${shorthash} to be confirmed ...`);
      await operation.receipt();

      props.toggleValue({
        id: boxOpen,
      });

      const values = actionsAsync(sk);

      values.then((result) => {
        props.setValue({
          id: stakingKind,
          ...result,
        });
      });

      setLoading(false);
      setIsOpenModal(false);
    } catch (error) {
      // console.error(error);
      setErrorSnack(error.message);
      setTimeout(hideSnack, 4000);
    }
  };

  const handleBoxOpen = (val) => {
    setBoxOpen(val);
    setModalValue(staking.find((el) => el.id === val));
  };

  const handleSetLockValue = (val, harvestLockedValue) => {
    props.setValue({
      id: val,
      harvestLocked: harvestLockedValue,
    });
  };

  const handleConfirm = () => {};

  return (
    <StakingPageWrapper>
      <Helmet>
        <title>
          DeFi
        </title>
        <meta name="description" content="Earn liquidity mining rewards by putting your $EASY to work" />
        <link rel="canonical" href="https://divercefi.com/defi" />

        {/* Facebook */}
        <meta property="og:title" content="DeFi" />
        <meta property="og:description" content="Earn liquidity mining rewards by putting your $EASY to work" />
        <meta
          property="og:image"
          content="https://divercefi.com/img/logo-1200x627.png"
        />
        <meta property="og:url" content="https://divercefi.com" />

        {/* Twitter */}
        <meta name="twitter:title" content="DeFi" />
        <meta name="twitter:description" content="Earn liquidity mining rewards by putting your $EASY to work" />
        <meta
          name="twitter:image"
          content="https://divercefi.com/img/logo-1200x627.png"
        />
        <meta name="twitter:card" content="https://divercefi.com" />
      </Helmet>
      <GeneralWrapp>
        <TopMenu fullpageApi={props.fullpageApi} logo className="mb-5" />
        <Header />
        <div className="staking">
          <div className="staking__body">
            <h2>
              <span className="d-flex align-center">
                Staking Pools 
                {' '}
                <span className="testVersion">BETA</span>
              </span>
              <span className="totalTVL d-flex flex-column">
                <span>
                  Total Value Locked (TVL):
                  {' '}
                  <span className="totalTVL__easy">
                    {withDecimals(totalTVL, 2)}
                    {' '}
                    EASY
                  </span>
                </span>
                <span className="totalTVL__usd">
                  ~
                  {withDecimals(totalTVL * priceUSD, 2)}
                  {' '}
                  USD
                </span>
              </span>
            </h2>
            {nowDate && (
              <Row className="staking__body__boxWrapper align-baseline">
                <Col lg="3">
                  <Box
                    showModal={(val) => handleModalOpen(val, 'pool1')}
                    handleBoxOpen={handleBoxOpen}
                    handleSetLockValue={handleSetLockValue}
                    confirm={handleConfirm}
                    interval={0}
                    priceUSD={priceUSD}
                    nowDate={nowDate}
                    apy="4"
                    tooltip1="Funds can be retrieved at any time"
                    id="pool1"
                    value={staking.find((el) => el.id === 'pool1')}
                    ready={ready}
                  />
                </Col>
                <Col lg="3">
                  <Box
                    showModal={(val) => handleModalOpen(val, 'pool2')}
                    handleBoxOpen={handleBoxOpen}
                    handleSetLockValue={handleSetLockValue}
                    confirm={handleConfirm}
                    interval={7}
                    priceUSD={priceUSD}
                    nowDate={nowDate}
                    apy="6"
                    tooltip1="Funds are locked for 7 days"
                    id="pool2"
                    value={staking.find((el) => el.id === 'pool2')}
                    ready={ready}
                  />
                </Col>
                <Col lg="3">
                  <Box
                    showModal={(val) => handleModalOpen(val, 'pool3')}
                    handleBoxOpen={handleBoxOpen}
                    handleSetLockValue={handleSetLockValue}
                    confirm={handleConfirm}
                    interval={30}
                    priceUSD={priceUSD}
                    nowDate={nowDate}
                    apy="12"
                    tooltip1="Funds are locked for 30 days"
                    id="pool3"
                    value={staking.find((el) => el.id === 'pool3')}
                    ready={ready}
                  />
                </Col>
                <Col lg="3">
                  <Box
                    showModal={(val) => handleModalOpen(val, 'pool4')}
                    handleBoxOpen={handleBoxOpen}
                    handleSetLockValue={handleSetLockValue}
                    confirm={handleConfirm}
                    interval={90}
                    priceUSD={priceUSD}
                    nowDate={nowDate}
                    apy="20"
                    tooltip1="Funds are locked for 90 days"
                    id="pool4"
                    value={staking.find((el) => el.id === 'pool4')}
                    ready={ready}
                  />
                </Col>
              </Row>
            )}
          </div>
        </div>
      </GeneralWrapp>
      <Modal
        isOpen={isOpenModal}
        handleClose={handleModalClose}
        modalType={modalType}
        handleChange={handleChange}
        confirmStake={handleConfirmStake}
        confirmHarvest={handleConfirmHarvest}
        val={modalValue}
        stakeValue={modalStakeValue}
        balance={modalBalance}
        boxOpen={boxOpen}
        loading={loading}
        priceUSD={priceUSD}
      />
      {loading ? <Loading /> : null}
    </StakingPageWrapper>
  );
};

const StakingPage = (props) => {
  return (
    <DAppProvider appName="Staking Page">
      <SettingsProvider>
        <SnackProvider>
          <StakingContent {...props} />
        </SnackProvider>
      </SettingsProvider>
    </DAppProvider>
  );
};

export default connect(
  (state) => ({
    staking: state.staking,
  }),
  { setValue, toggleValue }
)(withRouter(StakingPage));
