/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { SubscribeWrapper } from './styles';
import InputField from './InputField';
import Logo from '../../../../assets/img/Common/logo-with-text.png';
import { GeneralWrapp } from '../../../../components/Common/GeneralWrapp';
import Button from '../../../../components/Common/Button';

const SubscribeForm = () => {
  const [email, setEmail] = useState('');

  const [data, setData] = useState();
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${'26596402'}/${'2430a460-5436-4277-adbe-db4db3c09258'}`;
  const [form, setForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const dataObj = {
        fields: [
          {
            objectTypeId: '0-1',
            name: 'email',
            value: email,
          },
        ],
      };
      const result = await axios({
        method: 'post',
        url,
        data: dataObj,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setData(result);
      setForm(false);
    } catch (e) {
      setIsError(true);
      setForm(false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (form) {
      fetchData();
    }
  }, [form]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setForm(e.target);
  };

  const setEmailHandler = (val) => {
    setEmail(val.target.value);
  };

  return (
    <SubscribeWrapper>
      <form className="mc__form" onSubmit={handleSubmit}>
        {/* </SubscribeWrapper>> */}
        <GeneralWrapp>
          <h3 className="mc__title mb-4">
            <span className="mc__title--underline">Apply</span>
            {' '}
            to become a community
            <br />
            portfolio manager
            <br />
          </h3>
          <p className="mc__subtitle">
            Preparing our upcoming launch of social investing on Divercefi apply
            to become a community portfolio manager allowing you to build and
            manage virtual portfolios for the community, get your financial
            strategy featured and recognized, and earn tokens and collect
            referral fees.
          </p>

          <Row className="justify-content-center">
            <Col md={4} xs={12} className="d-none d-md-block">
              <img
                src={Logo}
                alt="Cryptoeasy"
                className="img-fluid mc__logo d-none d-md-block"
              />
              <h4 className="text-white">Join the future of investing</h4>
            </Col>
            <Col
              md={7}
              xs={12}
              className="d-flex flex-wrap align-content-center"
            >
              <Row className="w-100">
                <Col sm={12}>
                  <InputField
                    label="Email"
                    onChangeHandler={setEmailHandler}
                    type="email"
                    name="email"
                    value={email}
                    placeholder="Email*"
                    isRequired
                    size="md"
                  />
                </Col>
                <Col sm={12}>
                  {isError && (
                    <div className="mc__alert mc__alert--error">
                      Failed to submit form. Please try again
                    </div>
                  )}
                  {data && (
                    <div className="mc__alert mc__alert--success">
                      Thanks, you will get the confirmation via email now!
                    </div>
                  )}
                </Col>
                <Col sm={12}>
                  <div className="mc__submitBtn--wrapp d-flex justify-content-end">
                    <Button
                      label="join"
                      type="submit"
                      formValues={[email]}
                      disabled={isLoading}
                      green
                    >
                      {isLoading ? 'Joining...' : 'Join'}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </GeneralWrapp>
      </form>
    </SubscribeWrapper>
  );
};

export default SubscribeForm;
