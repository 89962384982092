const BigNumber = require('bignumber.js');

export const projectPool = (p) => {
  switch (p) {
    case 'pool1':
      return 0;
    case 'pool2':
      return 1;
    case 'pool3':
      return 2;
    case 'pool4':
      return 3;
    default:
      return 0;
  }
};

const execView = async (
  endpoint,
  chainid,
  contract,
  entrypoint,
  input,
  from,
  source
) => {
  const url = `${endpoint}/chains/main/blocks/head/helpers/scripts/run_view`;
  const payload = {
    chain_id: chainid,
    contract,
    entrypoint,
    gas: '100000',
    input,
    payer: from,
    source,
    unparsing_mode: 'Readable',
  };
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (response.ok) {
    // if HTTP-status is 200-299
    // get the response body (the method explained below)
    const json = await response.json();
    return json;
  }
  // console.log(err);
  console.error(`HTTP-Error: ${response.status}`);
};

export const getBalance = async (endpoint, chainid, token, pkh) => {
  const arg = [
    {
      prim: 'Pair',
      args: [{ string: pkh }, { int: '0' }],
    },
  ];
  const resp = await execView(
    endpoint,
    chainid,
    token,
    'balance_of',
    arg,
    pkh,
    pkh
  );
  // eslint-disable-next-line radix
  return resp ? Number.parseInt(resp.data[0].args[1].int) : null;
};

export const getActions = async (endpoint, chainid, staking, pkh, isk) => {
  const arg = {
    int: `${isk}`,
  };
  const resp = await execView(
    endpoint,
    chainid,
    staking,
    'getActions',
    arg,
    pkh,
    pkh
  );
  return resp;
};

export const getHarvest = async (endpoint, chainid, staking, pkh, isk) => {
  const arg = {
    int: `${isk}`,
  };
  const resp = await execView(
    endpoint,
    chainid,
    staking,
    'getHarvest',
    arg,
    pkh,
    pkh
  );
  if (resp) {
    const num = new BigNumber(resp.data.args[0].int);
    const denom = new BigNumber(resp.data.args[1].int);
    const harvest = num.dividedBy(denom);
    return harvest ? harvest.toString(10) : null;
  }
};

export const getStaked = async (endpoint, chainid, staking, pkh, isk) => {
  const arg = {
    int: `${isk}`,
  };
  const resp = await execView(
    endpoint,
    chainid,
    staking,
    'getStaked',
    arg,
    pkh,
    pkh
  );
  return resp ? resp.data.int : null;
};

export const getTVL = async (endpoint, chainid, staking, pkh, isk) => {
  const arg = {
    int: `${isk}`,
  };
  const resp = await execView(
    endpoint,
    chainid,
    staking,
    'getTVL',
    arg,
    pkh,
    pkh
  );
  return resp ? resp.data.int : null;
};

export const getPrice = async () => {
  const url = 'https://api.teztools.io/token/prices';

  const dataPrices = await fetch(url)
    .then(resp => resp.json())
    .then(data => data)
    .catch((error) => {
      console.error('Error:', error);
    });
  
  return dataPrices?.contracts.find(val => val.tokenAddress === 'KT1QgAtLPu3SNq9c6DPLanwL5bvfX3rgh2CS')?.usdValue;
};

export const withDecimals = (val, power) => {
  return val ? parseFloat((Math.round(val * (10 ** power)) / (10 ** power)).toFixed(power)) : 0;
};

// export const getReward = async (endpoint, chainid, staking, pkh, isk) => {
//   const arg = {
//     int: `${isk}`,
//   };
//   const resp = await execView(
//     endpoint,
//     chainid,
//     staking,
//     'getReward',
//     arg,
//     pkh,
//     pkh
//   );
//   return resp ? resp.data.int : null;
// };
