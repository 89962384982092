import styled from 'styled-components';

export const SolutionsWrapper = styled.div`
  // background: linear-gradient(45deg, rgba(37,26,95,1) 0%, rgba(27,85,117,1) 60%, rgba(25,240,174,1) 100%);
  background: #251a5f;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  padding-top: 72px;
  min-height: 100%;

  h1 {
    color: #00f0a9;
    font-weight: 700;
    font-size: 2em;
  }

  h3 {
    color: #fff;
    font-weight: 700;
    font-size: 1.4em;
  }

  .max-200 {
    width: 100%;
    max-width: 75px;
  }

  .content-grid {
    @media (max-width: 768px) {
      grid-row-gap: 40px;
    }
  }

  p {
    font-size: 1.3em;
    color: #fff;
    white-space: pre-line;
  }

  ul {
    list-style-image: url('/logo.svg');
    padding: 0 0 0 20px;
    
    li {
      font-size: 1.4em;
      font-weight: 500;
      color: #fff;
      white-space: pre-line;
      padding: 0 0 40px 10px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .zi1 {
    z-index: 1;
  }

  .carousel {
    min-height: 250px;

    @media (min-width: 576px) {
      min-height: 150px;
    }
    @media (min-width: 768px) {
      min-height: 650px;
    }

    &-control-next,
    &-control-prev {
      width: 25px;
      z-index: 1;

      @media (max-width: 767px) {
        display: none;
      }
    }

    &-control-next {
      right: -25px;
    }

    &-control-prev {
      left: -25px;
    }

    &-indicators {
      bottom: 0;
      left: 0;
      right: initial;
      margin: 0;
      margin-left: 50%;
      transform: translate(-50%, 0);
      position: absolute;

      [data-bs-target] {
        width: 50px;
        height: 2px;
        opacity: 1;
      }

      .active {
        background-color: #00f0a9;
      }
    }

    &-inner {
      @media (min-width: 576px) {
        min-height: 150px;
      }
      @media (min-width: 768px) {
        min-height: 650px;
      }
      @media (min-width: 2000px) {
        min-height: 750px;
      }
    }

    .fp-tableCell {
      height: 100% !important;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
    }
  }
  .fp-slides {
    overflow: visible !important;
  }

  .abs__img {
    &--0 {
      width: 75%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    &--1 {
      z-index: 1;
      bottom: -10%;
      
      &.left {
        right: 10%;
        width: 70%;

        @media (max-width: 768px) {
          right: 0;
        }
      }
      &.right {
        right: 0;
        width: 40%;
      }
    }
    &--3 {
      z-index: 1;
      top: 50px;
      right: 0;
    }
  }

  .shadow {
    &-lg {
      box-shadow: 0 1rem 3rem rgba(255, 255, 255, 0.175) !important;
    }
  }

  .customLink {
    color: #fff;
    text-decoration: underline; 

    &:hover {
      text-decoration: none;
    }
  }
`;

export const ExpandWrapp = styled.div`
  border: 3px solid ${(props) => (props.show ? 'transparent' : '#fff')};
  padding: 40px 15px;
  transition: all 0.3s ease;
  background: #251a5f;
  left: 0;
  top: 0;
  cursor: pointer;
  width: ${(props) => (props.show ? '100%' : 'initial')};
  min-height: 15vh;
  transition: border-color 0.3s ease-in-out;
  position: relative;
  z-index: 2;

  @media (min-width: 768px) {
    padding: 15px 25px;
    position: ${(props) => (props.show ? 'absolute' : 'initial')};
    height: ${(props) => (props.show ? '100%' : props.full ? '190px' : '200px')};
  }

  @media (min-width: 992px) {
    height: ${(props) => (props.show ? '100%' : props.full ? '150px' : '180px')};
  }

  h5,
  p {
    color: #fff;
    white-space: pre-line;
  }

  &:hover,
  &:focus {
    border-color: ${(props) => (props.show ? 'transparent' : '#00f0a9')};
    outline: 0;
    box-shadow: none;
  }

  .mc {
    &__title {
      color: #fff;
    }
  }
`;

export const ExpandHead = styled.div`
  display: ${(props) => (props.show ? 'none' : 'block')};
  text-align: center;

  .wrapp-icon {
    @media (min-width: 768px) {
      margin-right: 35px;
    }
    img {
      width: 75px;
    }
  }

  @media (min-width: 768px) {
    text-align: left;
  }
`;

export const ExpandBody = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};

  img {
    border: 3px solid
      ${(props) => (props.borderImg ? '#00F0A9' : 'transparent')};
    max-height: 300px;

    @media (min-width: 768px) {
      max-height: 480px;
    }
  }
`;

export const BackgroundComponent = styled.div`
  // background: radial-gradient(#251a5f 4px, transparent 4px), transparent;
  // background-position: -10px 0;
  // background-size: 30px 30px;
  background: transparent;
  width: 100%;
  height: 100%;
`;

export const CloseWrapp = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  font-size: 30px;
  line-height: 20px;
  font-weight: 700;
  padding: 10px;
  display: block;
  transition: all 0.3s ease-out;

  &:hover {
    opacity: 0.6;
  }
`;
