import React from 'react';

import Content from './Content';
import { PortfolioWrapper } from './styles';

const PortfolioSection = (props) => {
  return (
    <PortfolioWrapper id={props.id}>
      <Content imgClickHandler={props.imgClickHandler} fullpageApi={props.fullpageApi} />
    </PortfolioWrapper>
  );
};

export default PortfolioSection;
