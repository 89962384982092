import styled from 'styled-components';

export const SubscribeWrapper = styled.div`
  background: #251a5f;
  background-size: cover;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;

  @media (min-width: 768px) {
    padding-top: 60px;
  }

  .mc {
    &__form {
      width: 100%;
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 15px 0;
    }

    &__title {
      color: #fff;
      font-weight: 700;
      position: relative;
      display: inline-block;
      font-size: 24px;
      line-height: 1.6;

      @media (min-width: 768px) {
        font-size: 30px;
      }

      @media (min-width: 992px) {
        font-size: 36px;
      }
      &--underline {
        position: relative;

        &:after {
          content: '';
          background: #00f0a9;
          height: 5px;
          width: 125px;
          position: absolute;
          left: 0;
          bottom: -5px;
          width: 100%;

          // @media (min-width: 768px) {
          //   width: 155px;
          // }

          // @media (min-width: 992px) {
          //   width: 185px;
          // }
        }
      }
    }

    &__subtitle {
      margin-bottom: 50px;
      font-size: 16px;
      color: #fff;

      @media (min-width: 768px) {
        font-size: 18px;
      }

      @media (min-width: 992px) {
        font-size: 20px;
      }
    }

    &__logo {
      @media (min-width: 1200px) {
        padding: 0 30px;
      }
    }

    &__submitBtn {
      background: #00f0ac; //rgba(0, 240, 169, 1); //00F0AC
      color: #fff;
      font-weight: 700;
      text-transform: capitalize;
      padding: 10px 30px;
      border-radius: 4px;
      border: 1px solid #ced4da;

      &:hover,
      &:focus {
        background: rgba(0, 240, 169, 0.8);
      }
    }

    &__alert {
      &--error {
        color: #ff0000;
      }
      &--success {
        color: #00f0a9;
        font-weight: 700;
        font-size: 20px;

        &::before {
          content: '';
          display: inline-block;
          transform: rotate(45deg);
          height: 20px;
          width: 10px;
          border-bottom: 4px solid #00f0a9;
          border-right: 4px solid #00f0a9;
          margin-right: 12px;
        }
      }
    }
  }
`;
