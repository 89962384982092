import { createStore as reduxCreateStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';


import aboutReducer from '../reducers/aboutReducer';
import valuesReducer from '../reducers/valuesReducer';
import countryReducer from '../reducers/countryReducer';
import coinsReducer from '../reducers/coinsReducer';
import exchangesReducer from '../reducers/exchangesReducer';
import walletsReducer from '../reducers/walletsReducer';
import extrasReducer from '../reducers/extrasReducer';
import dashboardReducer from '../reducers/dashboardReducer';
import uiReducer from '../reducers/uiReducer';
import stakingReducer from '../reducers/stakingReducer';


const reducers = {
  about: aboutReducer,
  values: valuesReducer,
  country: countryReducer,
  coins: coinsReducer,
  exchanges: exchangesReducer,
  wallets: walletsReducer,
  extras: extrasReducer,
  dashboard: dashboardReducer,
  ui: uiReducer,
  staking: stakingReducer,
};

const reducer = combineReducers(reducers);

const createStore = () => reduxCreateStore(
  reducer,
  composeWithDevTools(applyMiddleware(thunk)),
);
export default createStore;
