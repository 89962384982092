import styled from 'styled-components';

export const TeamStyle = styled.div`
  .Core {
    display: flex;
    flex-wrap: wrap;
  }

  .TeamPhoto {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Personal {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    h3 {
      font-weight: 700;
      margin-bottom: 20px;
      color: #fff;
      font-size: 18px;
    }

    h4 {
      font-weight: 400;
      margin-bottom: 20px;
      color: #fff;
      font-size: 14px;
    }
  }

  .PhotoBox {
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;

    &__wrapp {
      max-width: 100%;
      max-width: 230px;
      width: 100%;
      border: 10px solid #251A5F;
      border-radius: 10px;
      margin-bottom: 20px;

      @media (min-width: 576px) {
        max-width: 130px;
      }

      @media (min-width: 768px) {
        max-width: 150px;
      }

      @media (min-width: 1200px) {
        max-width: 200px;
      }
    }

    
    a {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;

      svg {
        color: #fff;
        transition: color .3s ease;

        &:hover {
          color: #251A5F;
        }
      }

      @media (min-width: 768px) {
        right: -35px;
        bottom: -12px;
      }

      @media (min-width: 992px) {
        right: -50px;
      }
    }
  }

  .IndividualPhoto {
    width: 100%;
  }
`;
