import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link as ScrollLink, scroller } from 'react-scroll';
import {
  List,
  ListItem,
  Box,
} from '@material-ui/core';
import {
  StyledDrawer,
  StyledListItem,
  StyledListItemText,
} from '../Sidebar/styles';

const navList = [
  {
    id: 'approach',
    name: 'Approach',
    initial: 'T',
    children: [
      'Data gathering',
      'Weighted average volatility calculation',
      'Construction of the covariance matrix',
      'Portfolio volatility definition',
    ],
  },
  {
    id: 'conclusion',
    name: 'Conclusion',
    initial: 'D',
    // children: [
    //   'Definitions and key terms',
    // ],
  },
];

function ListItemButton(props) {
  return <ListItem button component="a" {...props} />;
}

const SecuritySidebar = () => {
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [chapter, setChapter] = useState('approach');

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash !== '') {
      const chapterName = hash.substring(0, hash.indexOf('-section'));
      setChapter(chapterName);
    }
  }, [location]);

  // const handleDrawer = () => {
  //   setOpen(!open);
  // };

  const handleSetActive = (to) => {
    const headerOffset = 70;
    scroller.scrollTo(to, {
      duration: 60,
      delay: 0,
      offset: -headerOffset,
    });
  };

  return (
    <StyledDrawer variant="permanent" open={open}>
      <List>
        {navList.map((nav) => (
          <Box key={nav.id}>
            <ScrollLink
              to={`${nav.id}-section1`}
              underline="none"
              onSetActive={handleSetActive}
              smooth
              duration={60}
              offset={-70}
            >
              <StyledListItem
                className={`${chapter === nav.id ? 'active' : ''} no-padding`}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <StyledListItemText
                    open
                    primary={open ? nav.name : nav.initial}
                  />
                </ListItemButton>
              </StyledListItem>
            </ScrollLink>
            {open &&
              chapter === nav.id &&
              nav.children &&
              nav.children.map((child, index) => (
                <ScrollLink
                  to={`${nav.id}-section${
                    index + 2
                  }`}
                  key={child}
                  underline="none"
                  onSetActive={handleSetActive}
                  smooth
                  duration={60}
                  offset={-70}
                >
                  <StyledListItem className="no-padding">
                    <ListItemButton
                      sx={{
                        justifyContent: 'center',
                        px: 4,
                        py: 0.5,
                      }}
                    >
                      <StyledListItemText
                        open={open}
                        primary={child}
                        size="small"
                      />
                    </ListItemButton>
                  </StyledListItem>
                </ScrollLink>
              ))}
          </Box>
        ))}
      </List>
    </StyledDrawer>
  );
};

export default SecuritySidebar;
