import React from 'react';

import Content from './Content';
import { FooterWrapper } from './styles';
import { GeneralWrapp } from '../Common/GeneralWrapp';

const Footer = (props) => {
  return (
    <FooterWrapper id={props.id}>
      <GeneralWrapp>
        <Content />
      </GeneralWrapp>
    </FooterWrapper>
  );
};

export default Footer;
