import React from 'react';
import ReactDOM from 'react-dom';
import { ModalProvider } from 'react-modal-hook';
import { TransitionGroup } from 'react-transition-group';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import ReactGA from 'react-ga4';
import { clarity } from 'react-microsoft-clarity';
import App from './App';
import { unregister } from './registerServiceWorker';

if (process.env.NODE_ENV !== 'development') {
  ReactGA.initialize('G-NDMDSYL6HD');
  clarity.init('jfm67h3qu9');
}

ReactDOM.render(
  <ModalProvider rootComponent={TransitionGroup}>
    <App />
  </ModalProvider>,
  document.getElementById('root'));
unregister();
