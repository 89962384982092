import React from 'react';
import FormControl from 'react-bootstrap/FormControl';
import styled from 'styled-components';

const InputFieldWrapp = styled.a`
  --size-bezel: 0.5rem;

  .input {
    position: relative;

    &__label {
      position: absolute;
      left: calc(var(--size-bezel) * 1.5);
      top: 0;
      padding: calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * 0.5);
      margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * 0.5);
      white-space: nowrap;
      transform: translate(0, 0);
      transform-origin: 0 0;
      background: transparent;
      transition: transform 120ms ease-in;
      font-weight: bold;
      line-height: 1.5;
      pointer-events: none;
      color: #1D1D1B;
    }

    &__field {
      box-sizing: border-box;
      display: block;
      width: 100%;
      border: 3px solid transparent;
      padding: calc(var(--size-bezel) * 1.5) calc(var(--size-bezel) * 2.5);
      color: #1D1D1B;
      background: #edf9f6;
      border-radius: 25px;
      border: 2px solid transparent;
  
      &:focus,
      &:not(:placeholder-shown) {
        box-shadow: none;

        & + .input__label {
          transform: translate(0.25rem, -90%) scale(0.8);
          color: #00F0A9;
        }
      }
    }
  }
`;

const inputField = (props) => {
  const { label, onChangeHandler, type, value, placeholder, isRequired, size } = props;

  return (
    <InputFieldWrapp>
      {/* <InputGroup
        className={classes.margin}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },
        }}
        InputProps={{
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          },
        }}
        label={label}
        variant="outlined"
        id="custom-css-outlined-input"
        onChange={onChangeHandler}
        type={type}
        value={value}
        placeholder={placeholder}
        required={isRequired}
      /> */}

      <div className="input mb-3">
        <FormControl
          placeholder=" "
          aria-label={placeholder}
          required={isRequired}
          size={size}
          value={value}
          type={type}
          onChange={onChangeHandler}
          className="input__field"
        />
        <span className="input__label">{label}</span>
      </div>
    </InputFieldWrapp>
  );
};

export default inputField;
