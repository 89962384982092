import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import HomePage from './pages/HomePage/HomePage';
import InvestorPage from './pages/InvestorPage';
import StakingPage from './pages/StakingPage';
import AboutUs from './pages/AboutUs';
import Security from './pages/Security';
import RiskScore from './pages/RiskScore';

import ScrollToTop from './components/ScrollToTop/ScrollToTop';

const Routes = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        <Route path="/portfolio-risk-score" component={RiskScore} />
        <Route path="/security" component={Security} />
        <Route path="/tokeneconomics" component={InvestorPage} />
        <Route path="/defi" component={StakingPage} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/" component={HomePage} />
      </Switch>
    </ScrollToTop>
  </Router>
);

export default Routes;
