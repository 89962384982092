import styled from 'styled-components';
import { Slider } from 'antd';
import EasyIcon from '../../../../assets/img/Common/logo.svg';

export const DialogContent = styled.div`
  background: #ffffff;
  border-radius: 25px;
  width: 100%;

  .dialog {
    &__header {
      background: #f4f4fb;
      border-bottom: 3px solid #d6d9db;
      padding: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: #251A5F;
        font-weight: 700;
        font-size: 16px;
        margin: 0;
      }

      .close {
        color: #251A5F;
        min-width: 20px;
        font-size: 18px;
      }
    }

    &__body {
      padding: 25px;

      .logo {
        img {
          max-width: 40px;
        }
      }

      p {
        color: #251A5F;
        font-weight: 700;
        font-size: 16px;
      }
    }
  }

  .btn {
    padding: 10px 50px;
    display: block;
    text-align: center;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;

    &--white {
      color: #000;
      background-color: #fff;
    }

    &--blue {
      color: #fff;
      background-color: #251A5F;
      padding: 10px 20px;
      border-radius: 10px;
    }

    &--grey {
      background: #dedede;
      color: #878787;
      padding: 10px 20px;
      border-radius: 10px;
      pointer-events: none;
    }

    &--orange {
      background: #f29e26;
      color: #fff;
      padding: 10px 20px;
      border-radius: 10px;
    }

    &--details {
      color: #251A5F;
      padding: 20px 20px 0;
    }
  }

  .d-flex {
    display: flex;
  }

  .space-between {
    justify-content: space-between;
  }

  .justify-center {
    justify-content: center;
  }

  .harvestVal {
    padding: 30px 0;

    &__value {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;

      &--easy {
        color: #000;
        font-size: 18px;
      }

      &--usd {
        color: #706e76;
        font-size: 16px;
        font-weight: 300;
      }
    }
  }

  .stakeVal {
    background: #f4f4fb;
    border-radius: 20px;
    padding: 20px;
    margin: 20px 0 10px;

    display: flex;
    justify-content: end;

    &__value {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      text-align: right;

      &--easy {
        color: #000;
        font-size: 18px;
      }

      &--usd {
        color: #706e76;
        font-size: 16px;
        font-weight: 300;
      }
    }
  }

  .balance {
    color: #251A5F;
    font-size: 14px;
    margin: 0 0 20px;
    text-align: right;
    justify-content: end;
  }

  .logo {
    font-size: 14px;
    font-weight: 700;

    span {
      margin-left: 5px;
    }
  }

  .slider {
    margin-bottom: 70px;
  }
  
  .input {
    margin: 20px 0;
    
    label {
      font-size: 14px;
      margin-bottom: 5px;
      color: #251A5F;
      width: 100%;
    }

    &__val {
      width: 100%;
      border: 2px solid #251A5F;
      border-radius: 3px;
      padding: 10px;
    }
  }

  .attention {
    border: 1px solid #E52421;
    padding: 10px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 700;
    font-style: italic;
    color: #E52421;

    img {
      margin-right: 10px;
    }
  }

  .loadingMsg {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .observation {
    margin: 10px 0 0;
    color: #251A5F;
    display: flex;
  }
`;

export const StyledSlider = styled(Slider)`
  height: 21px !important;
  width: 100%;
  @media (min-width: 992px) {
    flex-grow: 0;
  }
  .ant-slider-rail {
    background-color: white;
    height: 20px;
    border-radius: 15px;
  }
  .ant-slider-track {
    height: 20px;
    background-color: ${props => (props.modalType === 2 && props.danger ? '#FF0000' : '#251A5F')};
    border-radius: 15px;
  }
  .ant-slider-step {
    height: 20px;
    border-radius: 15px;
    border: 2px solid #251A5F;
  }
  .ant-slider-handle {
    width: 30px;
    height: 30px;
    background: #fff url('${EasyIcon}') no-repeat center center;
    background-size: contain;
    border: 0;
  }
  .ant-slider-dot {
    display: none;
  }

  .ant-slider-mark {
    width: 90%;
    font-size: 14px;
    left: 5%;
  }

  .ant-slider-mark-text {
    margin-top: 20px;
    color: #251A5F;
    font-size: 14px;
    font-weight: 700;
    background: #F4F4FB;
    border-radius: 5px;
    padding: 5px;
    width: 50px;

    // &:first-of-type {
    //   transform: translateX(0) !important;
    // }
    // &:nth-of-type(2) {
    //   transform: translateX(-33%) !important;
    // }
    // &:nth-of-type(3) {
    //   transform: translateX(-66%) !important;
    // }
    // &:last-of-type {
    //   transform: translateX(-100%) !important;
    // }
  }

  &:hover {
    .ant-slider-rail {
      background-color: white !important;
    }

    .ant-slider-track {
      background-color: ${props => (props.modalType === 2 && props.danger ? '#FF0000' : '#251A5F')} !important;
    }
  }
`;
