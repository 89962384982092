import styled from 'styled-components';

export const PortfolioWrapper = styled.div`
  // background: linear-gradient(45deg, rgba(37,26,95,1) 0%, rgba(27,85,117,1) 60%, rgba(25,240,174,1) 100%);
  background: #251a5f;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  padding-top: 60px;
  min-height: 100%;
  
  h1 {
    color: #00f0a9;
    font-weight: 700;
    font-size: 2em;
  }

  h3 {
    color: #fff;
    font-weight: 700;
    font-size: 1.4em;
  }

  .max-200 {
    width: 100%;
    max-width: 75px;
  }

  p {
    font-size: 1.2em;
    color: #fff;
    white-space: pre-line;
    min-height: 80px;
  }

  .zi1 {
    z-index: 1;
  }

  .abs__img {
    &--1 {
      z-index: 1;
      margin-left: 55%;
      margin-top: -25%;
    }
    &--3 {
      z-index: 1;
      top: 50px;
      right: 0;
    }
  }

  .shadow {
    &-lg {
      box-shadow: 0 1rem 3rem rgba(255,255,255,.175)!important;
    }
    &-sm {
      box-shadow: 0 .125rem .25rem rgba(255,255,255,.175)!important;
    }
  }

  .separator {
    &:after {
      content: '';
      position: absolute;
      right: 0;
      width: 1px;
      height: 100%;
      background: #fff;
    }

    &:last-of-type {
      &:after {
        display: none;
      }
    }
  }

  .padding-left-right {
    padding-right: 30px;
    padding-left: 30px;
  }

  .wrappSections {
    // border: .5px solid #fff;
    border-radius: 5px;
    padding: 15px;
    height: 100%;
    // background: #8A81AC;
    box-shadow: 0 .5rem 2rem rgba(0,0,0,.30)!important;
  }

  .overlay {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 5px; 
  }

  .badge {
    font-size: 12px;
    top: -15px;
    right: -25px;
    transform: rotate(30deg);
    color: #251a5f;
    background: #00f0a9;
    box-shadow: 0 .5rem 2rem rgba(0,0,0,.30);
  }

  .wrappElement {
    
  }
`;
