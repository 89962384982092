import styled from 'styled-components';

export const RoadmapStyle = styled.div`
  .fullImg {
    display: none;

    @media (min-width: 992px) {
      display: block;
    }
  }

  .roadmap {
    &__wrapp {
      display: flex;
      flex-wrap: wrap;

      @media (min-width: 992px) {
        display: none;
      }
    }

    &__component {
      color: ${props => props.theme.colors.black2};
      width: 100%;

      &__title {
        padding: 15px;
        // border: 4px dashed #00F0A9;
        color: #00F0A9;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin: 0 auto 15px;

        strong {
          font-size: 14px;
        }

        span {
          font-size: 18px;
          font-weight: 300;
        }
      }

      &__background {
        padding: 15px;
        background: #f6f6f6;
        border-radius: 15px;
        margin-bottom: 15px;
      }

      &__list {
        background: ${props => props.theme.colors.white};
        border-radius: 15px;
      }

      &__item {
        display: flex;
        align-items: center;
        padding: 10px;
      }

      &__text {
        font-weight: 300px;

        p {
          margin: 0;
        }
      }

      &__bullet {
        margin-right: 10px;
        display: flex;
        align-items: center;

        img {
          width: 20px;
        }
      }
    }
  }
`;
