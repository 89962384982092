import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0;
  display: flex;
  justify-content: space-between;

  ul {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;

    li {
      list-style: none;
      margin: 0 20px;
      font-weight: 700;
      font-size: 18px;
      width: 100%;

      a {
        color: #fff;
        position: relative;
        white-space: nowrap;

        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 0%;
          height: 2px;
          bottom: -5px;
          margin-top: -0.5px;
          background: #fff;
        }

        &:before {
          left: 0;
        }

        &:after {
          right: 0;
          background: #fff;
          transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
        }

        &.active,
        &:hover {
          color: #00f0a9;

          &:before {
            background: #00f0a9;
            width: 100%;
            transition: width 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
          }

          &:after {
            background: transparent;
            width: 100%;
            transition: 0s;
          }
        }
      }
    }
  }

  .logo {
    &--sm {
      max-width: 60px;
    }

    &--lg {
      max-width: 120px;
    }
  }
`;

export const BetaWrapper = styled.div`
  display: inline-flex;
  margin-left: 10px;
  padding: 2px 10px;
  background: #c0dcfd;
  border-radius: 12px;

  @media (min-width: 767px) {

  }
`;
