import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { PartnersWrapper, PartnerItem } from './styles';
import { GeneralWrapp } from '../../../../components/Common/GeneralWrapp';
import CedeStore from '../../../../assets/img/Partners/cede_store.svg';
import Tenity from '../../../../assets/img/Partners/tenity.svg';

const PartnersSection = () => {
  return (
    <PartnersWrapper>
      <GeneralWrapp>
        <h3 className="d-block mc__title mb-5 text-center">
          <span className="mc__title--underline">Partners</span>
        </h3>
        <Row className="partner-grid justify-content-center">
          <Col md={4} sm={6} xs={12}>
            <PartnerItem
              href="https://cede.store"
              target="_blank"
              rel="noreferrer"
            >
              <img src={CedeStore} alt="cede.store" className="img-fluid" />
            </PartnerItem>
          </Col>
          <Col md={4} sm={6} xs={12}>
            <PartnerItem
              href="https://tenity.com"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Tenity} alt="Tenity" className="img-fluid" />
            </PartnerItem>
          </Col>
        </Row>
      </GeneralWrapp>
    </PartnersWrapper>
  );
};

export default PartnersSection;
