import styled from 'styled-components';

export const Wrapper = styled.div`
  position: ${(props) => (props.isMobile ? 'absolute' : 'relative')};
  height: 60px;
  width: initial;
  background: transparent;
  right: 0;
  top: 0;
  z-index: 100;

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: relative;
    width: 40px;
    height: 30px;
    right: ${(props) => (props.isMobile ? '15px' : '0')};
    top: 15px;
    color: #00f0a9;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #00f0a9;
    transition: all 0.3s ease-out;
    border-radius: 10px;
    width: 40px;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    opacity: 0.6;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 40px !important;
    width: 40px !important;
    right: 20px !important;
    left: initial !important;

    & > span {
      top: 8px !important;
      right: 20px !important;
    }
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #00f0a9;
    height: 40px !important;
    width: 5px !important;
    border-radius: 10px;
  }

  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
    height: 100vh !important;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #251a5f;
    padding: 16px;
    overflow-x: hidden !important;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    left: 0;
    top: 0;
  }

  .topMenu {
    margin-top: 60px;
    list-style-type: none;
    padding-left: 0;
    outline: none;
    border: 0;
    display: flex;
    flex-direction: column;

    li {
      margin-bottom: 15px;
      a {
        color: #00f0a9;
        font-size: 25px;
        font-weight: 700;

        &.active {
          color: #fff;
        }
      }
    }
  }

  .bottomMenu {
    list-style-type: none;
    padding-left: 0;
    outline: none;
    border: 0;
    display: flex;
    flex-direction: column;

    li {
      text-align: center;

      a {
        color: #00f0a9;
        font-size: 16px;
        font-weight: 700;

        &.active {
          color: #fff;
        }
      }
    }
  }
`;
