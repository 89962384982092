import styled from 'styled-components';

export const GeneralWrapp = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 15px;

  @media(min-width: 1920px) {
    max-width: 1500px;
  }
`;
