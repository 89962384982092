const initialState = {
  isSubmitting: false,
};

export const TOGGLE_SUBMITTING = 'TOGGLE_SUBMITTING';

export const toggleSubmitting = value => ({ type: TOGGLE_SUBMITTING, value });

const coinsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SUBMITTING:
      return { ...state, isSubmitting: action.value };
    default:
      return state;
  }
};

export default coinsReducer;
