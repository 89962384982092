import styled from 'styled-components';

export const BoxStyle = styled.div`
  border: 1.5px solid #251A5F;
  border-radius: 20px;
  background: #fff;
  display: block;
  padding: 15px;
  width: 100%;
  overflow: hidden;
  margin: 15px 0;

  @media (min-width: 980px) {
    min-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .box {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 3px solid #d1d6d6;
      padding-bottom: 10px;

      .deprecated {
        font-size: 10px;
        font-weight: 500;
        color: #ff0000;
      }

      &--logo {
        font-size: 14px;
        font-weight: 700;
        color: #251A5F;

        img {
          max-width: 30px;
        }

        span {
          margin-left: 5px;
        }
      }

      &--status {
        color: #251A5F;
        font-size: 16px;
        font-weight: 700;
        display: flex;

        span {
          margin-right: 5px;
          text-align: right;
        }
      }
    }

    &__body {
      padding: 25px 0;
      min-height: 302px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__footer {
      border-top: 3px solid #d1d6d6;
    }
  }

  .apy {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;

    &__double {
      color: #00EFAB;
    }
  }

  .easyEarned {
    font-size: 12px;
    color: #251A5F;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 10px;
    text-transform: uppercase;
  }

  .harvest {
    font-size: 16px;
    font-weight: 700;

    &__values {
      display: flex;
      flex-direction: column;

      &--usd {
        color: #706e76;
        font-weight: 300;
        font-size: 14px;
      }
    }
  }

  .enableContract {
    &__value {
      display: flex;
      flex-direction: column;
      max-width: 50%;
      word-wrap: break-word;

      &--easy {
        font-size: 18px;
        font-weight: 700;
        color: #1d1d1b;
      }

      &--usd {
        font-size: 14px;
        font-weight: 300;
        color: #706e76;
      }
    }

    &__buttons {
      display: flex;

      &__button {
        background: #dedede;
        color: #f29e26;
        font-weight: 700;
        font-size: 30px;
        padding: 10px;
        border-radius: 10px;
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:first-of-type {
          margin-right: 10px;
        }
      }
    }
  }

  .expansionPanel {
    &__header {
      background-color: #f4f4fb;
      margin-right: -15px;
      margin-left: -15px;
      padding: 15px;

      &--title {
        font-size: 16px;
        font-weight: 700;
        color: #251A5F;
        margin-bottom: 5px;
      }
      &--subtitle {
        font-size: 16px;
        margin-bottom: 0px;
        display: flex;
        flex-direction: column;

        span {
          color: #706e76;
          font-size: 14px;
        }
      }
    }

    &__body {
      margin: 15px 0;

      table {
        font-size: 10px;
        text-align: left;

        @media (min-width: 1200px) {
          font-size: 12px;
        }

        th {
          color: #251A5F;
          padding: 10px 5px;
          font-weight: 700;
          text-align: left;

          &:first-of-type {
            width: 35%;
          }
        }

        td {
          color: #010101;
          padding: 10px 5px;
          text-align: left;

          &:first-of-type {
            width: 35%;
          }
        }
      }
    }
  }
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  color: ${(props) => (props.selected
    ? props.theme.colors.lightGrey
    : props.theme.colors.darkGrey)};
`;
