import styled from 'styled-components';

export const DistributionStyle = styled.div`
  .distribution {
    &__wrapp {
      @media (min-width: 992px) {
        display: flex;
      }
    }

    &__left {

      @media (min-width: 992px) {
        width: 30%;
        padding-right: 25px;
      }

      &__box {
        padding: 15px;
        background: #f4f4f4;
        border-radius: 15px;
        margin-bottom: 15px;
        text-align: center;

        img {
          max-width: 300px;
          margin: 0 auto;  
        }

        @media (min-width: 992px) {
          text-align: left;
        }

        @media (min-width: 1200px) {
          padding: 25px;
        }
      }
    }

    &__right {

      @media (min-width: 992px) {
        width: 70%;
      }

      table {
        width: 100%;
        margin-bottom: 15px;
        background: #f4f4f4;
        border-radius: 15px;
        font-size: 12px;

        @media (min-width: 576px) {
          font-size: 14px;
        }

        @media (min-width: 1200px) {
          font-size: 16px;
        }

        &.header {
          background: #00F0AC;
        }
      }

      td,
      th {
        padding: 10px;
        color: ${props => props.theme.colors.black2};

        @media (min-width: 768px) {
          padding: 15px;
        }

        &:first-of-type {
          @media (min-width: 1200px) {
            padding-left: 40px;
          }
        }
      }

      tr {
        &:first-of-type {
          td {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  .graphHistory {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 15px 0 15px;

    @media (min-width: 1200px) {
      
    }

    &__item {
      display: flex;
      width: auto;
      font-size: 12px;
      margin-bottom: 15px;
      padding-right: 20px;

      @media (min-width: 576px) {
        font-size: 14px;
      }

      @media (min-width: 1200px) {
        width: 50%;
        padding-right: 0;
      }

      &__color {
        width: 18px;
        min-width: 18px;
        height: 18px;
        border-radius: 5px;
        margin-right: 15px;

        @media (min-width: 768px) {
          margin-right: 5px;
        }

        &--1 {
          background: #595959;
        }

        &--2 {
          background: #d0cece;
        }

        &--3 {
          background: #0070be;
        }

        &--4 {
          background: #ffda69;
        }

        &--5 {
          background: #81702e;
        }
      }

      &__description {
        line-height: 1.2;
      }
    }
  }
`;
