import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';

// import Logo from '../../assets/img/Common/logo-top.svg';
// import LogoSmall from '../../assets/img/Common/logo.svg';
import { Wrapper } from './styles';
import Button from '../../Common/Button';
import SocialButtons from '../../SocialButtons';

const menuItems = [
  // { title: 'Home', link: '/' },
  // { title: 'DeFi(Deprecated)', link: '/defi' },
  // { title: 'Tokenomics', link: '/tokeneconomics' },
  // { title: 'Vision', link: '/vision' },
  // { title: 'About Us', link: '/about-us' },
];

const deprecatedItems = [{ title: 'DeFi(Deprecated)', link: '/defi' }];

const buttonsItems = [
  // { title: 'Stake', link: '/defi' },
  // { title: 'Buy $EASY', link: 'https://quipuswap.com/swap', external: true },
  {
    title: 'Whitepaper',
    link: 'https://github.com/cryptoeasy/whitepaper',
    external: true,
  },
  {
    title: 'Security and Privacy',
    link: '/security',
    external: false,
  },
  {
    title: 'Methodology',
    link: '/portfolio-risk-score',
    external: false,
  },
];

const BurgerMenu = ({ fullpageApi }) => {
  const { pathname } = window.location;
  const [isMobile, setIsMobile] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  }, [window]);

  return (
    <Wrapper isMobile={isMobile}>
      <Menu slide width={isMobile ? '100%' : '350px'}>
        <div className="d-flex flex-column justify-content-between h-100">
          <div>
            <ul className="topMenu mb-5">
              {menuItems.map((item) => (
                <li key={item.title}>
                  <Link
                    to={item.external ? { pathname: item.link } : item.link}
                    target={item.external ? '_blank' : ''}
                    onClick={() => pathname === '/' &&
                      item.slide &&
                      fullpageApi.moveTo(item.slide)}
                    className={pathname === item.link ? 'active' : null}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
            <div>
              {buttonsItems.map((item) => (
                <Button
                  href={item.link}
                  target={item.external ? '_blank' : ''}
                  title={item.title}
                  fullwidith
                  mb3
                >
                  {item.title}
                </Button>
              ))}
            </div>
          </div>
          <div>
            <ul className="bottomMenu">
              {deprecatedItems.map((item) => (
                <li key={item.title}>
                  <Link
                    to={item.external ? { pathname: item.link } : item.link}
                    target={item.external ? '_blank' : ''}
                    onClick={() => pathname === '/' &&
                      item.slide &&
                      fullpageApi.moveTo(item.slide)}
                    className={pathname === item.link ? 'active' : null}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
            <SocialButtons />
          </div>
        </div>
      </Menu>
    </Wrapper>
  );
  // );
};

export default BurgerMenu;
