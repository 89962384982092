import React, { useCallback, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import { useSettingsContext } from '../../../../settings';
import {
  useReady,
  useWallet,
  useConnect,
  useDisonnect,
  useAccountPkh,
} from '../../../../dappstate';
import { WalletInfo } from './styles';

const WalletButton = () => {
  const [walletAddress, setWalletAddress] = useState(null);
  const ready = useReady();
  const wallet = useWallet();
  const connect = useConnect();
  const disconnect = useDisonnect();
  const accountAddress = useAccountPkh();
  const { settings } = useSettingsContext();
  const handleConnect = useCallback(async () => {
    try {
      if (walletAddress) {
        await connect(settings.network);
      } else {
        await connect(settings.network, { forcePermission: true });
      }
      localStorage.setItem('CeWallet', settings.network);
    } catch (err) {
      // console.error(err.message);
    }
  }, [connect]);
  const refreshPage = () => {
    localStorage.removeItem('CeWallet');
    setWalletAddress(null);
    disconnect(settings.network);
  };

  useEffect(() => {
    const walletAddressC = localStorage.getItem('CeWallet');

    if (walletAddressC) {
      try {
        connect(settings.network);
        setWalletAddress(walletAddressC);
      } catch (err) {
        // console.log(err.message);
      }
    }
  }, [settings, connect]);

  return (
    <WalletInfo>
      {ready ? (
        <div className="account d-flex">
          <div className="account__address">
            {`${accountAddress.substr(0, 8)}...${accountAddress.substr(
              accountAddress.length - 8,
              accountAddress.length
            )}`}
          </div>
          <div
            className="account__logout"
            onClick={refreshPage}
            aria-hidden="true"
          >
            Log out
          </div>
        </div>
      ) : wallet ? (
        <Button
          variant="outlined"
          onClick={handleConnect}
          className="btn btn--white btn__hover--orange"
        >
          connect wallet
        </Button>
      ) : (
        <Link href="https://templewallet.com/" rel="noopener" underline="none">
          <Button
            variant="contained"
            className="btn btn--white btn__hover--orange"
          >
            install Temple
          </Button>
        </Link>
      )}
    </WalletInfo>
  );
};

export default WalletButton;
