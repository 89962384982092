import React from 'react';

import { DistributionStyle } from './styles';

import DistributionGraph from '../../../../assets/img/Investor/distribution-graph.svg';

const Distribution = () => {
  return (
    <DistributionStyle id="Distribution" className="mb50--section">
      <h1>Distribution</h1>

      <div className="distribution__wrapp">
        <div className="distribution__left">
          <div className="distribution__left__box">
            <h4 className="mb70">EASY Token allocations</h4>
            <img
              src={DistributionGraph}
              alt="Graph"
              className="img-responsive"
            />
          </div>
          <div className="distribution__left__box graphHistory">
            <div className="graphHistory__item">
              <span className="graphHistory__item__color graphHistory__item__color--1" />
              <span className="graphHistory__item__description">Reserved Staking</span>
            </div>
            <div className="graphHistory__item">
              <span className="graphHistory__item__color graphHistory__item__color--2" />
              <span className="graphHistory__item__description">Public IDO</span>
            </div>
            <div className="graphHistory__item">
              <span className="graphHistory__item__color graphHistory__item__color--3" />
              <span className="graphHistory__item__description">Team</span>
            </div>
            <div className="graphHistory__item">
              <span className="graphHistory__item__color graphHistory__item__color--4" />
              <span className="graphHistory__item__description">
                Private sales
              </span>
            </div>
            <div className="graphHistory__item">
              <span className="graphHistory__item__color graphHistory__item__color--5" />
              <span className="graphHistory__item__description">
                Business development
              </span>
            </div>
          </div>
        </div>
        <div className="distribution__right">
          <table className="header">
            <thead>
              <tr>
                <th width="35%">EASY Token allocations</th>
                <th width="20%">% of total</th>
                <th width="25%">Vesting</th>
                <th width="20%">Lockup</th>
              </tr>
            </thead>
          </table>

          <table>
            <tbody>
              <tr>
                <td width="35%">
                  <strong>Reserved Staking</strong>
                </td>
                <td width="20%">50%*</td>
                <td width="25%">depending on DeFi</td>
                <td width="20%">6 months</td>
              </tr>
              <tr>
                <td colSpan="4">
                  Staking will be comprised of classical Staking and Portfolio Staking that will emit
                  EASY following the Proof of Return mechanism.
                  <br />
                  {' '}
                  <i>*6 months freezed liquidity on Crunchy.Network</i>
                </td>
              </tr>
            </tbody>
          </table>

          <table>
            <tbody>
              <tr>
                <td width="35%">
                  <strong>Public IDO</strong>
                </td>
                <td width="20%">16.5%</td>
                <td width="25%">25/09/2021</td>
                <td width="20%">-</td>
              </tr>
              <tr>
                <td colSpan="4">
                  The tokens released through the IDO on Rocket Launchpad will be liquid, free floating and exchangeable.
                </td>
              </tr>
            </tbody>
          </table>

          <table>
            <tbody>
              <tr>
                <td width="35%">
                  <strong>Team</strong>
                </td>
                <td width="20%">12%</td>
                <td width="25%">3 years</td>
                <td width="20%">6 months</td>
              </tr>
              <tr>
                <td colSpan="4">
                  The team dedicated tokens will be used to reward core team members and advisors.
                  The vesting and lockup periods will incentivize to deliver on the Divercefi solutions
                </td>
              </tr>
            </tbody>
          </table>

          <table>
            <tbody>
              <tr>
                <td width="35%">
                  <strong>Private Sale</strong>
                </td>
                <td width="20%">10%</td>
                <td width="25%">3 months</td>
                <td width="20%">9 months</td>
              </tr>
              <tr>
                <td colSpan="4">
                  The Private Sales at a 35-40% discount at the early stage before the IDO enabling to provide Divercefi early funds to develop its first solutions.
                </td>
              </tr>
            </tbody>
          </table>

          <table>
            <tbody>
              <tr>
                <td width="35%">
                  <strong>Business development</strong>
                </td>
                <td width="20%">11.5%</td>
                <td width="25%">variable</td>
                <td width="20%">variable</td>
              </tr>
              <tr>
                <td colSpan="4">
                  Business development encompasses tokens gifted through marketing contests, air drops, and similar marketing and community activities. Moreover, those tokens can be used to reward business development activities such as raising capital or events.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </DistributionStyle>
  );
};

export default Distribution;
