import styled from 'styled-components';

export const HeaderStyle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-top: 70px;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    padding-top: 0;
  }

  h1 {
    color: #fff;
    font-weight: 700;
    font-size: 28px;
    margin: 0 0 15px;
    
    @media (min-width: 768px) {
      flex-direction: row;
      padding-top: 0;
      margin: 0;
    }

    @media (min-width: 980px) {
      font-size: 36px;
    }
  }

  .backBtn {
    display: flex;
    align-items: center;
    color: #fff;

    a {
      display: flex;
      align-items: center;
      color: #fff;
      transition: all 0.3s ease;

      &:hover,
      &:active {
        opacity: 0.8;
      }
    }
  }

  .title {
    align-items: center;
    
    @media (min-width: 768px) {
      align-items: flex-start;
    }
  }
`;
