import { getExchanges } from '../lib/api';

const initialState = {
  isFetching: true,
  fetched: false,
  list: [],
};

export const EXCHANGES_LOAD = 'EXCHANGES_LOAD';
export const TOGGLE_FETCHING_EXCHANGES = 'TOGGLE_FETCHING_EXCHANGES';
export const TOGGLE_FETCHED_EXCHANGES = 'TOGGLE_FETCHED_EXCHANGES';
export const TOGGLE_EXCHANGE = 'TOGGLE_EXCHANGE';

export const loadExchanges = data => ({ type: EXCHANGES_LOAD, payload: data });
export const toggleFetching = value => ({ type: TOGGLE_FETCHING_EXCHANGES, value });
export const toggleFetched = value => ({ type: TOGGLE_FETCHED_EXCHANGES, value });
export const toggleExchange = id => ({ type: TOGGLE_EXCHANGE, id });

export const fetchExchanges = () => {
  return async (dispatch) => {
    try {
      dispatch(toggleFetching(true));
      const exchanges = await getExchanges();
      dispatch(loadExchanges(exchanges));
      dispatch(toggleFetching(false));
      dispatch(toggleFetched(true));
      return exchanges;
    } catch (error) {
      return Error('Error fetching and parsing data', error);
    }
  };
};

/* eslint-disable no-confusing-arrow */

const exchangesReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXCHANGES_LOAD:
      return { ...state, list: action.payload };
    case TOGGLE_FETCHING_EXCHANGES:
      return { ...state, isFetching: action.value };
    case TOGGLE_FETCHED_EXCHANGES:
      return { ...state, fetched: action.value };
    case TOGGLE_EXCHANGE:
      return { ...state, list: state.list.map(value => (value.id === action.id) ? { ...value, selected: !value.selected } : value) };
    default:
      return state;
  }
};

export default exchangesReducer;
