import React from 'react';
import { Grid } from '@material-ui/core';
import { StyledTypography } from '../../styles';

const Disclaimer = () => {
  return (
    <Grid container spacing={2} sx={{ color: 'white' }}>
      <Grid item xs={12} id="conclusion-section1">
        <StyledTypography className="title">
          Conclusion
        </StyledTypography>
                
        <StyledTypography>
          In essence, this methodology enables us to comprehend and quantify the risk associated with a financial portfolio, taking into account the individual assets, their significance, and their interactions. It is of the utmost importance for the formulation of well-informed investment decisions.
          {/* <br />
          <br />
          <u>The Purpose:</u>
          {' '}
          This code is designed to help you analyze and understand the risk associated with different combinations of cryptocurrencies in your investment portfolio.
          <br />
          <br />
          <u>Random Portfolio Creation:</u>
          {' '}
          The code will randomly pick a few cryptos from the bag (randomly select cryptocurrencies) and assign different weights to each one (representing their importance in the portfolio) to create unique bags (portfolios).
          <br />
          <br />
          <u>Risk Measurement:</u>
          {' '}
          The code will measure price volatility in cryptocurrencies by looking at their price changes over the last 30, 60, and 90 days.
          <br />
          <br />
          <u>Correlation:</u>
          {' '}
          It will also check if the price changes are related to each other in the bag (correlation) to understand how the cryptocurrencies behave together.
          <br />
          <br />
          <u>Risk Evaluation:</u>
          {' '}
          By considering both the importance of each cryptocurrency and how much their price changes vary, the code will evaluate the overall risk of each bag (portfolio).
          <br />
          <br />
          <u>Repeated Process:</u>
          {' '}
          The code will repeat this process multiple times, creating several different bags (portfolios) and evaluating their risk levels.
          <br />
          <br />
          <u>Results and Visualization:</u>
          {' '}
          The code will generate charts and statistics to help you visualize the risk distribution across the portfolios. It will show you the average risk, the most common risk level, and other important information.
          <br />
          <br />
          <u>Data Saving:</u>
          {' '}
          All the results will be saved in a file, so you can review and compare the riskiness of different portfolios easily.
          <br />
          <br />
          In summary, this code will create and evaluate different combinations of cryptocurrencies in your investment portfolio, helping you understand which combinations are riskier and which ones are potentially safer based on their price changes over different periods. The code provides valuable insights to make informed investment decisions and manage your portfolio effectively. */}

        </StyledTypography>
      </Grid>
    </Grid>
  );
};

export default Disclaimer;
