import React from 'react';

import { RightContentWrapp } from './styles';
import Tokeneconomics from './componenets/Tokeneconomics';
import Distribution from './componenets/Distribution';
import Projections from './componenets/Projections';
import Roadmap from './componenets/Roadmap';

const TokeneconomicsValue = [
  {
    title: 'Ticker Symbol',
    subtitle: 'EASY',
  },
  {
    title: 'Standards',
    subtitle: 'FA2 (Tezos)',
  },
  {
    title: 'Total Initial Supply',
    subtitle: '6,000,000',
  },
  {
    title: 'IDO Token Swap Rate',
    subtitle: '0.0326 XTZ/ $0.2',
  },
  {
    title: 'Quipuswap List Rate',
    subtitle: '0.0434 XTZ/ $0.267',
  },
];

const ProjectionsValues = [
  ['$ Tezos Price at time of IDO', '6.13', '7', '8', '9', '10'],
  [
    '$ EASY Price for Private Sale',
    '0.125',
    '0.125',
    '0.125',
    '0.125',
    '0.125',
  ],
  ['$ EASY Price at IDO', '0.2', '0,2', '0,2', '0,2', '0,2'],
  [
    '$ EASY Price at listing on Quipuswap',
    '0.25',
    '0,25',
    '0,25',
    '0,25',
    '0,25',
  ],
  ['% of Tezos Price', '3.26 %', '2.86 %', '2.5 %', '2.2 %', '2.0 %'],
  false,
  [
    'Number of Tokens of TOTAL initial Supply',
    '6’000’000',
    '6’000’000',
    '6’000’000',
    '6’000’000',
    '6’000’000',
  ],
  [
    'Number of Tokens raised in IDO',
    '987’842',
    '987’842',
    '987’842',
    '987’842',
    '987’842',
  ],
  [
    'Number of Tokens raised in Private Sale',
    '600’000',
    '600’000',
    '600’000',
    '600’000',
    '600’000',
  ],
  false,
  [
    '$ Market cap raised by Private Sale',
    '74’959',
    '74’959',
    '74’959',
    '74’959',
    '74’959',
  ],
  [
    '$ Market cap raised by IDO',
    '197’527',
    '197’527',
    '197’527',
    '197’527',
    '197’527',
  ],
  [
    '$ Market cap circulating supply by Nov 2021 (EASY listing price)',
    '266’661',
    '266’661',
    '266’661',
    '266’661',
    '266’661',
  ],
  [
    '$ Market cap circulating supply by Q1 2022 (EASY listing price)',
    '339’088',
    '339’088',
    '339’088',
    '339’088',
    '339’088',
  ],
  [
    '% of Tezos projected market cap Q1 2022 (EASY listing price)',
    '0.0064 %',
    '0.0056 %',
    '0.0049 %',
    '0.0044 %',
    '0.0039 %',
  ],
  // false,
  // [
  //   '$ market cap final 2022/2023 (EASY same price)',
  //   '600’000',
  //   '600’000',
  //   '600’000',
  //   '600’000',
  // ],
  // [
  //   '$ market cap final 2022/2023 (EASY x5)',
  //   '3’000’000',
  //   '3’000’000',
  //   '3’000’000',
  //   '3’000’000',
  // ],
  // [
  //   '$ market cap final 2022/2023 (EASY x10)',
  //   '6’000’000',
  //   '6’000’000',
  //   '6’000’000',
  //   '6’000’000',
  // ],
  // [
  //   '$ market cap final 2022/2023 (EASY x50)',
  //   '30’000’000',
  //   '30’000’000',
  //   '30’000’000',
  //   '30’000’000',
  // ],
  // false,
  // [
  //   '$ market cap final 2023+ (EASY same price)',
  //   '1’200’000',
  //   '1’200’000',
  //   '1’200’000',
  //   '1’200’000',
  // ],
  // [
  //   'market cap final 2023+ (EASY x5)',
  //   '6’000’000',
  //   '6’000’000',
  //   '6’000’000',
  //   '6’000’000',
  // ],
  // [
  //   '$ market cap final 2023+ (EASY x10)',
  //   '12’000’000',
  //   '12’000’000',
  //   '12’000’000',
  //   '12’000’000',
  // ],
  // [
  //   '$ market cap final 2023+ (EASY x50)',
  //   '60’000’000',
  //   '60’000’000',
  //   '60’000’000',
  //   '60’000’000',
  // ],
];

const RoadmapValue = [
  {
    key: 'RoadmapValue1',
    title: 'Aug.',
    subtitle: '2021',
    list: [
      'Launch of Divercefi.com landing page and social media channels',
      'Partnership and IDO campaign with Rocket Launchpad (now Crunchy)',
      'Discussions and collaboration with technical partners in the Tezos ecosystem (e.g., Nomadic labs, Completium)',
      'Publication of the business whitepaper on Github',
    ],
  },
  {
    key: 'RoadmapValue2',
    title: 'Sept.',
    subtitle: '2021',
    list: [
      'Launch of the investor page to provide transparency on tokenomics ',
      'Onboarding of new team members, partners, and advisors to support the marketing, business, and technical development',
      'Publication of articles in renowned Crypto-specialized news websites (e.g., XTZ.News)',
      '$EASY Token Private Sale to early investors',
      '$EASY Token Public Sale and IDO (Initial DEX Offering) on Quipuswap',
    ],
  },
  {
    key: 'RoadmapValue3',
    title: 'Oct.',
    subtitle: '2021',
    list: [
      'Listing of $EASY Token on further DEXs (e.g., Smartlink)',
      'Discussion for further partnerships with business partners',
      'Initiation of the creation of Divercefi AG in Zug, Switzerland',
      'Onboarding of further full-time team members',
      'Kick-off of core development of Divercefi DeFi solutions (starting with Staking page)',
    ],
  },
  {
    key: 'RoadmapValue4',
    title: 'Nov. /Dec.',
    subtitle: '2021',
    list: [
      'Launch of $EASY Token staking pools',
      'Talks with market makers and DEXs to plan listings and farming solutions',
      'First talks with investors to raise a Seed Funding',
      'Launch of new website and rebranding',
      'Architecture and testing of Divercefi DeFi and Portfolio Staking solutions',
      'First revenues generated by Divercefi',
    ],
  },
  {
    key: 'RoadmapValue5',
    title: 'Q1',
    subtitle: '2022',
    list: [
      'First airdrops and marketing campaigns',
      'Open-sourcing of smart contract and technical whitepaper',
      'Announcement of first major partnerships',
      'First Token listings on Crypto Platforms',
      'Launch of the Investor profile to onboard users in the Crypto market',
    ],
  },
  {
    key: 'RoadmapValue6',
    title: 'Q2',
    subtitle: '2022',
    list: [
      'Launch of the Portfolio Explorer and first portfolios open to $EASY Portfolio Staking',
      'First portfolios proposed by partners/ community',
      'Audit of the code and full documentation available to the public',
      'Extension of the core team on the technical and business side',
      'Announcement of further strategic partnerships',
      'Launch of a referral program',
    ],
  },
  {
    key: 'RoadmapValue7',
    title: 'Q3/Q4',
    subtitle: '2022',
    list: [
      'Extension of the Portfolio Explorer with new key features (e.g., managed portfolios, portfolio design)',
      'Feeless transactions and direct payment of $EASY tokens on Divercefi.com',
      'Launch of the synthetic assets offering together with DEX pairs and liquidity mining',
      'Token listing on Tier 2 CEXs',
      'Launch of the mobile app',
    ],
  },
  {
    key: 'RoadmapValue8',
    title: '2023',
    subtitle: false,
    list: [
      'Launch of the DeFi Dashboard with SSO account integration (CeFi & DeFi)',
      'Further extension of synthetics asset offering and integration with partners',
      'Integration with Stablecoins to assure more price stability',
      'Extension of Portfolio Staking to other asset classes',
      'Token listing on Tier 1 CEXs',
      'Multi-chain deployment beyond Tezos',
    ],
  },
  {
    key: 'RoadmapValue9',
    title: '2024',
    subtitle: false,
    list: [
      'Potential launch of security tokens',
      'Go-live of DAO capabilities to empower and reward the community',
      'Series A to scale Divercefi',
      'Launch of B2B integration offerings and B2C premium subscriptions around the DeFi Dashboard',
      'Inauguration of corporate offices in Zug and virtual offices on several metaverse ecosystems',
    ],
  },
];

const RightContent = ({ imgClickHandler }) => {
  return (
    <RightContentWrapp>
      <Tokeneconomics data={TokeneconomicsValue} />
      <Distribution />
      <Projections data={ProjectionsValues} imgClickHandler={imgClickHandler} />
      <Roadmap data={RoadmapValue} imgClickHandler={imgClickHandler} />
    </RightContentWrapp>
  );
};

export default RightContent;
