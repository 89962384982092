import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// import particlesConfig from './particlesConfig.json';

const LeftWrapp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 15px 15px 0;

  @media (min-width: 768px) {
    width: ${props => (props.small ? '20%' : '35%')};
    padding: 0 15px;
    position: fixed;

    .fixElement {
      height: 55px;
    }
  }
`;

export const WrappParticles = styled.div`
  canvas {
    pointer-events: none !important;
  }
`;

const Left = ({ children, small }) => {
  // const [isMobile, setIsMobile] = useState();

  // const handleResize = () => {
  //   if (window.innerWidth < 768) {
  //     setIsMobile(true);
  //   } else {
  //     setIsMobile(false);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('resize', handleResize);
  //   handleResize();
  // }, [window]);

  return (
    <LeftWrapp small={small}>
      {/* {!isMobile && (
        <WrappParticles>
          <Particles
            style={{
              position: 'absolute',
              height: '100%',
              width: '40%',
            }}
            params={particlesConfig}
          />
        </WrappParticles>
      )} */}
      {children}
    </LeftWrapp>
  );
};

Left.propTypes = {
  small: PropTypes.bool.isRequired,
};

export default Left;
