import { List, ListItem, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export const StyledTypography = styled(Typography)(() => ({
  '&.heading': {
    fontSize: 40,
    fontWeight: 700,
  },
  '&.title': {
    fontSize: 24,
    fontWeight: 600,
  },
  '&.center': {
    textAlign: 'center',
  },
  fontFamily: 'Montserrat',
  fontSize: 14,
  fontWeight: 400,
  color: '#fff',
}));

export const StyledList = styled(List)(() => ({
  paddingLeft: 16,
}));

export const StyledListItem = styled(ListItem)(() => ({
  display: 'list-item',
  listStyle: 'disc',
  paddingLeft: 0,
  color: '#fff',
}));
