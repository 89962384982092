import React from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

const Wrapper = styled.span`
  display: flex;
  margin-bottom: ${(props) => (props.mb3 ? '30px' : '0')};

  .btn {
    border: 2px solid transparent;
    background-color: initial;
    font-weight: bold;
    font-size: 14px;
    color: ${(props) => (props.purple ? '#fff' : '#251A5F')};
    border-radius: 25px;
    line-height: 30px;
    padding: 10px 15px !important
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in;
    width: ${(props) => (props.fullwidith ? '100%' : 'initial')};
    position: relative;

    @media (min-width: 992px) {
      ;
    }

    span {
      z-index: 2; 
      position: relative;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: ${(props) => (props.purple ? '#251A5F' : props.green ? '#00F0A9' : '#fff')};
      border-radius: 25px;
      transition: all 0.3s;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      transition: all 0.3s;
      border: 2px solid ${(props) => (props.purple ? '#251A5F' : props.green ? '#00F0A9' : '#fff')};
      border-radius: 25px;
      transform: scale(1.2, 1.2);
    }

    &:hover {
      cursor: pointer;
      color: ${(props) => (props.hoverColor ? props.hoverColor : props.purple ? '#251A5F' : '#fff')};

      &:before {
        opacity: 0;
        transform: scale(0.5, 0.5);
      }

      &:after {
        opacity: 1;
        transform: scale(1,1);
      }
    }
    &:active {
      outline: none;
      border: none;
    }
    &:focus {
      outline: none;
    }
    @media (min-width: 992px) {
      padding: 10px 50px;
    }
  }
`;

const ButtonEl = ({ iconBefore, iconAfter, children, ...props }) => {
  return (
    <Wrapper {...props}>
      <Button {...props}><span>{children}</span></Button>
    </Wrapper>
  );
};

export default ButtonEl;
