import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

export const handleScrollToTop = (location, prevLocation) => {
  if (location !== prevLocation) {
    window.scrollTo(0, 0);
  }
};

export class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    handleScrollToTop(this.props.location, prevProps.location);
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ScrollToTop.propTypes = {
  location: PropTypes.shape({}),
};

ScrollToTop.defaultProps = {
  location: '',
};

export default withRouter(ScrollToTop);
