import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { ContentWrapper } from './styles';
import SocialButtons from '../SocialButtons';

const Content = () => {
  return (
    <ContentWrapper>
      <Row className="justify-content-center">
        <Col xs={12}>
          <SocialButtons />
        </Col>
        <Col xs={12}>
          <p className="text-left">
            The material contained on this website is for informational purposes only and Divercefi AG is not soliciting any action based upon such material. The material is not to be construed as an offer or a recommendation to buy or sell a 
            security, cryptocurrencies, or EASY Token itself nor is it to be construed as investment advice. Additionally, the material accessible through this website does not constitute a representation that the investments described herein are 
            suitable or appropriate for any person. For further information please check our Terms and Conditions as well as the Privacy Policy.
          </p>
        </Col>
      </Row>
    </ContentWrapper>
  );
};

export default Content;
