import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  List,
  ListItem,
  Divider,
  IconButton,
  Box,
  Link,
} from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import {
  StyledDrawer,
  DrawerHeader,
  StyledListItem,
  StyledListItemText,
} from '../Sidebar/styles';

const navList = [
  {
    id: 'terms-conditions',
    name: 'Terms & Conditions',
    initial: 'T',
    children: [
      'Definitions and key terms',
      'What Information Do We Collect?',
      'When does Divercefi use end user information from third parties?',
      'When does Divercefi use customer information from third parties?',
      'Do we share the information we collect with third parties?',
      'Where and when is information collected from customers and end users?',
      'How Do We Use The Information We Collect?',
      'How Do We Use Your Email Address?',
      'How Long Do We Keep Your Information?',
      'How Do We Protect Your Information?',
      'Could my information be transferred to other countries?',
      'Is the information collected through the Divercefi Service secure?',
      'Can I update or correct my information?',
      'Personnel',
      'Sale of Business',
      'Affiliates',
      'Governing Law',
      'Your Consent',
      'Links to Other Websites',
      'Cookies',
      'Blocking and disabling cookies and similar technologies',
      'Remarketing Services',
      'Kids\' Privacy',
      'Changes To Our Privacy Policy',
      'Third-Party Services',
      'Tracking Technologies',
      'Information about General Data Protection Regulation (GDPR)',
      'What is GDPR?',
      'What is personal data?',
      'Why is GDPR important?',
      'Individual Data Subject\'s Rights - Data Access, Portability and Deletion',
      'Contact Us',
    ],
  },
  {
    id: 'disclaimer',
    name: 'Dislcaimer',
    initial: 'D',
    children: [
      'Definitions and key terms',
      'Limited liability',
      'Links to Other Websites Disclaimer',
      'Errors and Omissions Disclaimer',
      'General Disclaimer',
      'Copyright Disclaimer',
      'Affiliate Links Disclosure',
      'Financial Disclosure',
      'Educational Disclosure',
      'Your Consent',
      'Changes To Our Disclaimer',
      'Contact Us',
    ],
  },
  {
    id: 'cookie',
    name: 'Cookie Policy',
    initial: 'C',
    children: [
      'Definitions and key terms',
      'Introduction',
      'What is a cookie?',
      'Why do we use cookies?',
      'What type of cookies does Divercefi.com use?',
      'Essential Cookies',
      'Performance and Functionality Cookies',
      'Marketing Cookies',
      'Analytics and Customization Cookies',
      'Advertising Cookies',
      'Social Media Cookies',
      'Third Party Cookies',
      'How you can manage cookies?',
      'Blocking and disabling cookies and similar technologies',
      'Changes To Our Cookie Policy',
      'Your Consent',
      'Contact Us',
    ],
  },
];

function ListItemButton(props) {
  return <ListItem button component="a" {...props} />;
}

const SecuritySidebar = () => {
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [chapter, setChapter] = useState('terms-conditions');

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash !== '') {
      const chapterName = hash.substring(0, hash.indexOf('-section'));
      setChapter(chapterName);
    }
  }, [location]);

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <StyledDrawer variant="permanent" open={open}>
      {/* <DrawerHeader>
        <IconButton onClick={handleDrawer}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader> */}
      {/* <Divider /> */}
      <List>
        {navList.map((nav) => (
          <Box key={nav.id}>
            <Link
              href={`/security#${nav.id}-section1`}
              underline="none"
            >
              <StyledListItem
                className={`${chapter === nav.id ? 'active' : ''} no-padding`}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <StyledListItemText
                    open
                    primary={open ? nav.name : nav.initial}
                  />
                </ListItemButton>
              </StyledListItem>
            </Link>
            {open &&
              chapter === nav.id &&
              nav.children &&
              nav.children.map((child, index) => (
                <Link
                  href={`/security#${nav.id}-section${
                    index + 2
                  }`}
                  key={child}
                  underline="none"
                >
                  <StyledListItem className="no-padding">
                    <ListItemButton
                      sx={{
                        justifyContent: 'center',
                        px: 4,
                        py: 0.5,
                      }}
                    >
                      <StyledListItemText
                        open={open}
                        primary={child}
                        size="small"
                      />
                    </ListItemButton>
                  </StyledListItem>
                </Link>
              ))}
          </Box>
        ))}
      </List>
    </StyledDrawer>
  );
};

export default SecuritySidebar;
