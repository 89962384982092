import { getDashboard } from '../lib/api';

const initialState = {
  isFetching: true,
  fetched: false,
  budget: 345,
  coins: [],
  projections: {
    returns: {
      oneMonth: 0,
      threeMonths: 0,
      sixMonths: 0,
      oneYear: 0,
    },
    volatility: {
      oneMonth: 0,
      threeMonths: 0,
      sixMonths: 0,
      oneYear: 0,
    },
  },
  journey: [],
};

export const DASHBOARD_LOAD = 'DASHBOARD_LOAD';
export const SET_BUDGET = 'SET_BUDGET';
export const TOGGLE_FETCHING_DASHBOARD = 'TOGGLE_FETCHING_DASHBOARD';
export const TOGGLE_FETCHED_DASHBOARD = 'TOGGLE_FETCHED_DASHBOARD';

export const loadDashboard = data => ({ type: DASHBOARD_LOAD, payload: data });
export const setBudget = value => ({ type: SET_BUDGET, value });
export const toggleFetching = value => ({ type: TOGGLE_FETCHING_DASHBOARD, value });
export const toggleFetched = value => ({ type: TOGGLE_FETCHED_DASHBOARD, value });

export const fetchDashboard = () => {
  return async (dispatch) => {
    try {
      dispatch(toggleFetching(true));
      const dashboard = await getDashboard();
      dispatch(loadDashboard(dashboard));
      dispatch(toggleFetching(false));
      dispatch(toggleFetched(true));
      return dashboard;
    } catch (error) {
      return Error('Error fetching and parsing data', error);
    }
  };
};

/* eslint-disable no-confusing-arrow */

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_LOAD:
      return { ...state, ...action.payload };
    case SET_BUDGET:
      return { ...state, budget: action.value };
    case TOGGLE_FETCHING_DASHBOARD:
      return { ...state, isFetching: action.value };
    case TOGGLE_FETCHED_DASHBOARD:
      return { ...state, fetched: action.value };
    default:
      return state;
  }
};

export default dashboardReducer;
