import { values } from '../data/valuesData';

const initialState = [...values];

export const TOGGLE_VALUE = 'TOGGLE_VALUE';

export const toggleValue = id => ({
  type: TOGGLE_VALUE,
  id,
});

/* eslint-disable no-confusing-arrow */

const valuesReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_VALUE:
      return state.map(value => (value.id === action.id) ? { ...value, selected: !value.selected } : value);
    default:
      return state;
  }
};

export default valuesReducer;
