import styled from 'styled-components';

export const ContentWrapper = styled.div`
  padding: 10px 0;

  p {
    color: #fff;
    text-align: left;
    font-size: 12px !important;
  }
`;

export const FooterWrapper = styled.div`
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background: #251a5f;
`;
