import React from 'react';
import styled from 'styled-components';
import { Link as ScrollLink } from 'react-scroll';

const LeftWrapp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .backBtn {
    display: flex;
    justify-content: flex-start;

    @media (min-width: 1920px) {
      font-size: 24px;
    }

    @media (min-width: 768px) {
      padding-top: 20px;
      justify-content: center;
    }

    a {
      display: flex;
      align-items: center;
      color: #fff;
      transition: all 0.3s ease;

      &:hover,
      &:active {
        opacity: 0.8;
      }
    }
  }
`;

export const LeftButtons = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }

  .button {
    color: #00f0ac;
    display: block;
    transition: all 0.3s ease;
    font-size: 16px;
    position: relative;
    padding: 10px 0;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 0%;
      height: 2px;
      bottom: 15px;
      margin-top: -0.5px;
      background: #00f0ac;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
      background: #00f0ac;
      transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
    }

    @media (min-width: 1920px) {
      font-size: 24px;
    }

    &:hover,
    &:active,
    &.active {
      @media (min-width: 768px) {
        padding: 25px 0;
        font-size: 18px;
        font-weight: 700;

        &:before {
          background: #00f0ac;
          width: 100%;
          transition: width 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
        }

        &:after {
          background: transparent;
          width: 100%;
          transition: 0s;
        }
      }

      @media (min-width: 1920px) {
        font-size: 28px;
      }
    }
  }
`;

const LeftContent = () => {
  return (
    <LeftWrapp>
      <div className="centerBtn">
        <LeftButtons>
          <ScrollLink to="Overview" spy hashSpy className="button">
            Overview
          </ScrollLink>
        </LeftButtons>
        <LeftButtons>
          <ScrollLink to="Distribution" spy hashSpy className="button">
            Distribution
          </ScrollLink>
        </LeftButtons>
        <LeftButtons>
          <ScrollLink to="Projections" spy hashSpy className="button">
            Projected Supply
          </ScrollLink>
        </LeftButtons>
        <LeftButtons>
          <ScrollLink to="Roadmap" spy hashSpy className="button">
            Roadmap
          </ScrollLink>
        </LeftButtons>
      </div>
      <div />
    </LeftWrapp>
  );
};

export default LeftContent;
