const initialState = {};

export const SET_ABOUT = 'SET_ABOUT';

export const setAbout = payload => ({
  type: SET_ABOUT,
  payload,
});

const about = (state = initialState, action) => {
  switch (action.type) {
    case SET_ABOUT:
      return action.payload;
    default:
      return state;
  }
};

export default about;
