import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedin,
  faTelegramPlane,
  faDiscord
} from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';

import medium from '../../assets/img/HomePage/medium.svg';
import ProductHuntLogo from '../../assets/img/Partners/product-hunt-logo-black-240.png';

export const SocialButton = styled.div`
  display: inline-block;
  font-size: 30px;
  padding-right: 10px;
  padding-top: 10px;
  transition: 0.3s ease;

  &:last-of-type {
    padding-right: 0;
  }

  img {
    font-size: 20px;
  }

  .medium {
    height: 35px;
    padding-bottom: 5px;
  }

  .ContactUs {
    color: #fff;
    padding: 5px 10px;
    opacity: 0.9;
    display: block;
    font-size: 28px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const MediaButtons = styled.div`
  text-align: center;
`;

const SocialButtons = () => {
  return (
    <MediaButtons>
      <SocialButton>
        <a
          href="https://ch.linkedin.com/company/divercefi"
          target="_blank"
          rel="noopener noreferrer"
          title="LinkedIn Divercefi"
        >
          <FontAwesomeIcon
            icon={faLinkedin}
            style={{ fontSize: '25px', color: '#fff' }}
          />
        </a>
      </SocialButton>

      <SocialButton>
        <a
          href="https://twitter.com/divercefi"
          target="_blank"
          rel="noopener noreferrer"
          title="Twitter Divercefi"
        >
          <FontAwesomeIcon
            icon={faTwitter}
            style={{ fontSize: '25px', color: '#fff' }}
          />
        </a>
      </SocialButton>

      {/* <SocialButton>
        <a
          href="https://t.me/cryptoeasy_chat"
          target="_blank"
          rel="noopener noreferrer"
          title="Cryptoeasy Chat"
        >
          <FontAwesomeIcon
            icon={faTelegramPlane}
            style={{ fontSize: '25px', color: '#fff' }}
          />
        </a>
      </SocialButton> */}

      <SocialButton>
        <a
          href="https://t.me/+WR-HMc3BX1hiNTg8"
          target="_blank"
          rel="noopener noreferrer"
          title="Cryptoeasy Official Announcements"
        >
          <FontAwesomeIcon
            icon={faTelegramPlane}
            style={{ fontSize: '25px', color: '#fff' }}
          />
        </a>
      </SocialButton>

      <SocialButton>
        <a
          href="https://instagram.com/divercefi?igshid=Y2IzZGU1MTFhOQ=="
          target="_blank"
          rel="noopener noreferrer"
          title="Instagram Divercefi"
        >
          <FontAwesomeIcon
            icon={faInstagram}
            style={{ fontSize: '25px', color: '#fff' }}
          />
        </a>
      </SocialButton>

      <SocialButton>
        <a
          href="https://medium.com/@cryptoeasy"
          target="_blank"
          rel="noopener noreferrer"
          title="Medium Divercefi"
        >
          <img alt="" src={medium} className="medium" />
        </a>
      </SocialButton>

      <SocialButton>
        <a
          href="https://discord.com/invite/mPGnCsVud3"
          target="_blank"
          rel="noopener noreferrer"
          title="Medium Divercefi"
        >
          <FontAwesomeIcon
            icon={faDiscord}
            style={{ fontSize: '25px', color: '#fff' }}
          />
        </a>
      </SocialButton>

      <SocialButton>
        <a
          href="https://www.producthunt.com/products/divercefi"
          target="_blank"
          rel="noopener noreferrer"
          title="Product Hunt Divercefi"
        >
          <img src={ProductHuntLogo} alt="product hunt" className="medium" />
        </a>
      </SocialButton>

      {/* <SocialButton>
        <a
          className="ContactUs"
          href="https://linktr.ee/realcryptoeasy"
          target="_blank"
          rel="noopener noreferrer"
          title="Contact Us"
        >
          Contact Us
        </a>
      </SocialButton> */}

      {/* <SocialButton>
      <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faYoutube} style={{ fontSize: '25px', color: '#fff' }} />
      </a>
    </SocialButton> */}
    </MediaButtons>
  );
};

export default SocialButtons;
