import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';

// import Overlay from 'react-bootstrap/Overlay';
// import Tooltip from 'react-bootstrap/Tooltip';
import { Wrapper, BetaWrapper } from './styles';
import BurgerMenu from './BurgerMenu';
import LogoImg from '../../assets/img/Common/logo.svg';

const menuItems = [
  {
    title: 'Log In',
    link: 'https://app.divercefi.com/',
    external: true,
    beta: true
    // link: '/#subscribe',
    // slide: 'subscribe',
  },
  // {
  //   title: 'Docs',
  //   link: 'https://github.com/cryptoeasy/whitepaper',
  //   external: true,
  // },
];

const TopMenu = ({ className }) => {
  const { pathname } = window.location;
  const [isMobile, setIsMobile] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  }, [window]);

  return isMobile ? (
    <BurgerMenu style={{ left: '10px' }} />
  ) : (
    <Wrapper className={className}>
      <div className="d-flex align-items-center">
        <Link to="/" className="me-4">
          <img src={LogoImg} width={50} height={50} alt="logo" />
        </Link>
        <BurgerMenu />
      </div>
      <div className="d-flex">
        <ul className="topMenu">
          {menuItems.map((item) => (
            <li key={item.title}>
              <Link
                to={item.external ? { pathname: item.link } : item.link}
                target={item.external ? '_blank' : ''}
                title={item.title}
                onClick={() => {
                  return (
                    pathname === '/' &&
                    item.slide &&
                    window.fullpage_api.moveTo(item.slide)
                  );
                }}
                className={pathname === item.link ? 'active' : null}
              >
                {item.title}
                {item.beta && (
                  <BetaWrapper>
                    <Typography style={{ color: '#45549c' }}>Beta</Typography>
                  </BetaWrapper>
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Wrapper>
  );
};

export default TopMenu;
