import React, { useState, useEffect } from 'react';
import Siriwave from 'react-siriwave';

import Content from './Content';
import { TopSectionWrapper } from './styles';
import { GeneralWrapp } from '../../../../components/Common/GeneralWrapp';

const TopSection = (props) => {
  const [isMobile, setIsMobile] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  }, [window]);

  return (
    <TopSectionWrapper
      id={props.id}
      className="d-flex flex-column justify-content-between"
    >
      <GeneralWrapp className="flex-grow-1">
        <Content />
      </GeneralWrapp>

      <div className="wrappWave">
        <Siriwave
          color="#00F0A9"
          speed={0.01}
          amplitude={isMobile ? 0.5 : 1}
          frequency={isMobile ? 3 : 5}
          cover
          pixelDepth={0.01}
          lerpSpeed={1}
        />
      </div>
    </TopSectionWrapper>
  );
};

export default TopSection;
