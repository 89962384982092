import { useState } from 'react';
import constate from 'constate';

export function useSettings() {
  const [settings, setState] = useState({
    network: 'mainnet',
    chainid: 'NetXdQprcVkpaWU',
    endpoint: 'https://mainnet.api.tez.ie',
    staking: 'KT1LVAMuSBEWUo9zHHrkuXBaxQ7kLdzNG1ci',
    token: 'KT1QgAtLPu3SNq9c6DPLanwL5bvfX3rgh2CS',
    show: false,
  });

  const setNetwork = (nw) => {
    setState((s) => {
      return { ...s, network: nw };
    });
  };

  const setEndpoint = (ep) => {
    setState((s) => {
      return { ...s, endpoint: ep };
    });
  };

  const setChainid = (ci) => {
    setState((s) => {
      return { ...s, chainid: ci };
    });
  };

  const setContract = (c) => {
    setState((s) => {
      return { ...s, contract: c };
    });
  };

  const hideSettings = () => {
    setState((s) => {
      return { ...s, show: false };
    });
  };

  const showSettings = () => {
    setState((s) => {
      return { ...s, show: true };
    });
  };

  const getBcdUrl = () => {
    return `https://better-call.dev/${settings.network}/${settings.contract}`;
  };

  return {
    settings, setNetwork, setEndpoint, setContract, hideSettings, showSettings, getBcdUrl, setChainid
  };
}

export const [SettingsProvider, useSettingsContext] = constate(useSettings);

export const appName = 'My First Completium DApp';

// fonts
export const courier = 'Courier Prime, monospace';
export const alegreya = 'Alegreya Sans SC, sans-serif';
