const initialState = [
  {
    id: 'pool1',
    enableContract: false,
    stakedValue: 0,
    harvestValue: 0,
    tvl: 0,
    harvestLocked: false,
    actions: []
  },
  {
    id: 'pool2',
    enableContract: false,
    stakedValue: 0,
    harvestValue: 0,
    tvl: 0,
    harvestLocked: false,
    actions: []
  },
  {
    id: 'pool3',
    enableContract: false,
    stakedValue: 0,
    harvestValue: 0,
    tvl: 0,
    harvestLocked: false,
    actions: []
  },
  {
    id: 'pool4',
    enableContract: false,
    stakedValue: 0,
    harvestValue: 0,
    tvl: 0,
    harvestLocked: false,
    actions: []
  },
];

export const SET_STAKING_VALUE = 'SET_STAKING_VALUE';
export const TOGGLE_STAKING_VALUE = 'TOGGLE_STAKING_VALUE';

export const setValue = value => ({ type: SET_STAKING_VALUE, value });
export const toggleValue = value => ({ type: TOGGLE_STAKING_VALUE, value });

const stakingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STAKING_VALUE:
      return state.map(value => ((value.id === action.value.id) ? { ...value, ...action.value } : value));
    case TOGGLE_STAKING_VALUE:
      return state.map(value => ((value.id === action.value.id) ? { ...value, enableContract: true } : value));
    default:
      return state;
  }
};

export default stakingReducer;
