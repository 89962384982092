import styled from 'styled-components';

export const TokeneconomicsStyle = styled.div`
  .tokeneconomics {
    &__table {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      font-size: 12px;
      padding: 5px 15px;
      border-radius: 30px;
      background: #fff;

      @media (min-width: 576px) {
        flex-direction: row;
        padding: 15px;
      }

      &__row {
        border-bottom: 1px solid #ceb475;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 10px 10px;
        width: 100%;

        @media (min-width: 576px) {
          border-right: 2px solid #ceb475;
          border-bottom: none;
        }

        @media (min-width: 768px) {
          padding: 5px 20px;
        }

        &:last-child {
          border-right: none;
          border-bottom: none;
        }
      }
    }
  }
`;
