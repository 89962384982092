import styled from 'styled-components';
import Locker from '../../assets/img/StakingPage/locker.svg';

export const StakingPageWrapper = styled.div`
  background-color: #251A5F;
  font-size: 20px;
  min-height: 100vh;
  padding-bottom: 30px;

  .btn {
    padding: 10px 50px;
    display: block;
    text-align: center;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;

    &--white {
      color: #000;
      background-color: #fff;
    }

    &--blue {
      color: #fff;
      background-color: #251A5F;
      padding: 10px 20px;
      border-radius: 10px;
    }

    &--grey {
      background: #dedede;
      color: #878787;
      padding: 10px 20px;
      border-radius: 10px;
      pointer-events: none;
    }

    &--orange {
      background: #f29e26;
      color: #fff;
      padding: 10px 20px;
      border-radius: 10px;
    }

    &--details {
      color: #251A5F;
      padding: 10px !important;
    }

    &--disable {
      pointer-events: none;
      opacity: 0.7;
    }

    &--locked {
      pointer-events: none;
      background: #dedede;
      color: #00EFAB;
      position: relative;

      &:before {
        content: '';
        background: transparent url('${Locker}') no-repeat center center;
        position: absolute;
        top: -11px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 20px;
        height: 26px;
      }
    }

    &__hover {
      &--orange {
        &:hover {
          background: #00f0a9;
          color: #fff;
        }
      }
    }
  }

  h2 {
    color: #000;
    font-weight: 700;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .staking {
    background-color: #f5f5fc;
    border-radius: 20px;
    padding: 15px 25px;

    @media (min-width: 1200px) {
      padding: 30px 50px;
    }

    &__body {
      &__boxWrapper {
        flex-direction: column;

        @media (min-width: 980px) {
          flex-direction: row;
        }
      }
    }
  }

  .space-between {
    justify-content: space-between;
  }

  .align-baseline {
    align-items: baseline;
  }

  .align-center {
    align-items: center;
  }

  .flex-column {
    flex-direction: column;
  }

  .align-flex-start {
    align-items: flex-start;
  }

  .content__wrapp {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
  }

  .testVersion {
    color: #251A5F;
    padding: 2px 5px;
    border: 1px solid #251A5F;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    margin: 0 10px;
    text-align: center;

    &--orange {
      color: #251A5F;
      border: 1px solid #00EFAB;
    }
    &--green {
      color: #00EFAB;
    }
    &--deprecated {
      color: #ff0000;
      border: 2px solid #ff0000;
      font-size: inherit;
    }
  }

  .maintenance {
    font-size: 14px;
    color: #878787;
  }

  .totalTVL {
    font-size: 14px;
    color: #251A5F;
    text-align: center;

    @media (min-width: 768px) {
      text-align: right;
    }

    &__easy {
      color: #000;
    }
    
    &__usd {
      color: #706e76;
      margin-left: 5px;
      font-size: 12px;
      font-weight: 300;
    }
  }

  @media (min-width: 768px) {
  }
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  color: #8492a8;
`;
