export const values = [
  {
    id: 1,
    title: 'Privacy',
    image: '/img/values/privacy.svg',
    description: 'I don\'t want my transactions to be tracked - Anonymity and Privacy are very important to me.',
    selected: false,
  },
  {
    id: 2,
    title: 'NFT',
    image: '/img/values/nft.svg',
    description: 'I think non-fungible tokens are here to stay! Whether it\'s trading cards, artwork, or tweets - I believe in ownership of unique digital items on a blockchain.',
    selected: false,
  },
  {
    id: 3,
    title: 'Staking (passive income)',
    image: '/img/values/staking.svg',
    description: 'I want to earn interest on my cryptocurrencies just by holding them.',
    selected: false,
  },
  {
    id: 4,
    title: 'Meme Tokens',
    image: '/img/values/meme.svg',
    description: 'I don\'t care about value based projects, I am in for the fun! Dogecoin, Garlicoin, Poocoin,...',
    selected: false,
  },
  {
    id: 5,
    title: 'DeFi',
    image: '/img/values/tezos.svg',
    description: 'I believe that traditional brokerages, banks and exchanges will be disrupted by blockchain based decentralized finance applications.',
    selected: false,
  },
  {
    id: 6,
    title: 'Gaming',
    image: '/img/values/gaming.svg',
    description: 'I love using crypto to buy and trade items in gaming communities.',
    selected: false,
  },
  {
    id: 7,
    title: 'Proof-of-Work',
    image: '/img/values/proof-of-work.svg',
    description: 'I believe in Proof of Work blockchains where hashing power is key to mine new coins.',
    selected: false,
  },
  {
    id: 8,
    title: 'Ethereum tokens',
    image: '/img/values/ethereum-token.svg',
    description: 'I believe in Ethereum projects and its large ecosystem for DApps.',
    selected: false,
  },
  {
    id: 9,
    title: 'Binance ecosystem',
    image: '/img/values/binance.svg',
    description: 'I love Binance and its native BSC blockchain. Funds are SAFU with CZ!',
    selected: false,
  },
  {
    id: 10,
    title: 'Polkadot ecosystem',
    image: '/img/values/polkadot.svg',
    description: 'I believe that Polkadot and the Web3 foundation are one of the most innovative projects out there.',
    selected: false,
  },
  {
    id: 11,
    title: 'Utility/ Dapps',
    image: '/img/values/dapps.svg',
    description: 'I believe in projects that have "real-world" tangible use cases and applications.',
    selected: false,
  },
  {
    id: 12,
    title: 'IOT/Logistics',
    image: '/img/values/iota.svg',
    description: 'I believe blockchain can disrupt the logistics industry by making shipping faster and more efficient and improve data visibility and demand management.',
    selected: false,
  },
  {
    id: 13,
    title: 'Oracles (Web 3.0)',
    image: '/img/values/oracle.svg',
    description: 'I believe that blockchain will be more useful if integrated with real-world external data.',
    selected: false,
  },
  {
    id: 14,
    title: 'Healthcare',
    image: '/img/values/healthcare.svg',
    description: 'I want to support projects disrupting healthcare industry.',
    selected: false,
  },
  {
    id: 15,
    title: 'Telecommunications',
    image: '/img/values/telecommunications.svg',
    description: 'I want to support projects disrupting telecommunications industry.',
    selected: false,
  },
  {
    id: 16,
    title: 'Sustainability',
    image: '/img/values/sustainability.svg',
    description: 'I want to support projects that are conscious about environmental and social considerations.',
    selected: false,
  },
  {
    id: 17,
    title: 'Loyalty tokens',
    image: '/img/values/loyalty-token.svg',
    description: 'I want to be rewarded for using specific services or buying products.',
    selected: false,
  },
  {
    id: 18,
    title: 'Filesharing/ Cloud',
    image: '/img/values/file-sharing.svg',
    description: 'I believe in tokens that enable to share data in a decentralized and secured fashion.',
    selected: false,
  },
  {
    id: 19,
    title: 'Ethereum killers',
    image: '/img/values/ethereum-killer.svg',
    description: 'I believe that Ethereum alone is not sufficient and that other blockchains can complement or even replace Ethereum for smart contracts.',
    selected: false,
  },
  {
    id: 20,
    title: 'Scalability',
    image: '/img/values/scalability.svg',
    description: 'I believe in projects that make decentralized technologies more scalable to support millions of users accross the world.',
    selected: false,
  },
  {
    id: 21,
    title: 'High staking rewards',
    image: '/img/values/high-staking.svg',
    description: 'I am not satisfied with standard staking rates - I am interested in projects with at least double digit staking returns!',
    selected: false,
  },
  {
    id: 22,
    title: 'Security tokens',
    image: '/img/values/security-token.svg',
    description: 'I like very centralized tokens',
    selected: false,
  },
  {
    id: 23,
    title: 'Stablecoins',
    image: '/img/values/stablecoin.svg',
    description: 'I am not a big fan of huge volatility - I want to invest in stablecoins bringing constant interests and enabling easy and cheap transfer mechanisms.',
    selected: false,
  },
  {
    id: 24,
    title: 'Payments',
    image: '/img/values/payments.svg',
    description: 'I believe cryptocurrencies offer cheap and international medium of payment.',
    selected: false,
  },
  {
    id: 25,
    title: 'Governance token',
    image: '/img/values/governance-token.svg',
    description: 'I believe on governance of a protocol based on decentralized organization and a software platform that allows users to issue and manage DAI & AMPL stablecoins (or other coins).',
    selected: false,
  },
  {
    id: 26,
    title: 'Bitcoin Maximalist',
    image: '/img/values/bitcoin-maximalist.svg',
    description: 'I believe that Bitcoin is King and the only digital asset that will be needed in future.',
    selected: false,
  },
  {
    id: 27,
    title: 'Web 4.0',
    image: '/img/values/web-40.svg',
    description: 'I believe in internet 4.0. - I believe in the world’s first blockchain that runs at web speed with unbounded capacity where query calls execute in milliseconds providing a fantastic user experience.',
    selected: false,
  },
];
