import React from 'react';

import { TokeneconomicsStyle } from './styles';

const Tokeneconomics = ({ data }) => {
  return (
    <TokeneconomicsStyle id="Overview" className="mb50--section">
      <h1>Tokenomics at a glance</h1>
      <div className="tokeneconomics__table">
        {data.map(val => (
          <div className="tokeneconomics__table__row" key={val.title}>
            <span>{val.title}</span>
            <strong>{val.subtitle}</strong>
          </div>
        ))}
      </div>
    </TokeneconomicsStyle>
  );
};

export default Tokeneconomics;
