import React from 'react';

import { Wrapper } from './styles';

const TopMenu = ({ children }) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
};

export default TopMenu;
