import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import moment from 'moment';
import CountUp from 'react-countup';
import { Image } from 'react-bootstrap';

import { BoxStyle, TooltipWrapper } from './styles';
import EasyIcon from '../../../../assets/img/Common/logo.svg';
import { withDecimals } from '../../helpers';

const styles = {
  root1: {
    boxShadow: 'none',
  },
  root2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root3: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  content2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    margin: '10px 0 0',
    '& > :last-child': {
      padding: 100,
    },
  },
  expanded: {
    margin: 0,
  },
};

const Box = ({
  interval,
  showModal,
  value,
  handleBoxOpen,
  id,
  classes,
  // ready,
  apy,
  tooltip1,
  handleSetLockValue,
  priceUSD,
  nowDate
}) => {
  const [expandedPanel, setExpandedPanel] = useState(false);
  const [harvestLocked, setHarvestLocked] = useState(false);
  const [harvestValue, setHarvestValue] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [prevHarvestValue, setPrevHarvestValue] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [averageDate, setAverageDate] = useState(0);

  const handleAccordionChange = () => {
    setExpandedPanel(!expandedPanel);
  };

  useEffect(() => {
    const isLocked = value.actions?.find((val) => {
      return (
        val?.args[0]?.int === '0' &&
        moment
          .unix(val?.args[1]?.args[1]?.int)
          .add(interval, 'd')
          .diff(moment(nowDate)) <= 0
      );
    });
    setHarvestLocked(isLocked ? false : true);
  }, [value, id]);

  useEffect(() => {
    const sums = value?.actions
      ?.map((val) => (val?.args[1]?.args[0]?.args &&
        moment
          .unix(val?.args[1]?.args[1]?.int)
          .add(interval, 'd')
          .diff(moment(nowDate)) < 0
        ? parseInt(
          val?.args[0]?.int === '0'
            ? val?.args[1]?.args[0]?.args[0]?.int / 1000000
            : val?.args[0]?.int === '1'
              ? -val?.args[1]?.args[0]?.args[0]?.int / 1000000
              : 0,
          10
        )
        : 0)
      )
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

    const dateAvrArr = value?.actions
      ?.filter((val) => val?.args[0]?.int === '0')
      .map((val) => parseInt(val?.args[1]?.args[1]?.int, 10));
    const dateAvrSum = dateAvrArr.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );

    setAverageDate(dateAvrSum / dateAvrArr.length);

    handleSetLockValue(id, sums);
  }, [value?.actions, id]);

  useEffect(() => {
    setHarvestValue(value.harvestValue);
  }, [value.harvestValue]);

  // const yearSeconds = 31536000;
  // const rCon = Math.log(1 + parseInt(apy, 10));

  // useEffect(() => {
  //   if (value.enableContract) {
  //     const harvestInterval = setTimeout(() => {
  //       const iCon =
  //         rCon *
  //         harvestValue *
  //         Math.exp(
  //           rCon * ((moment(nowDate).unix() - averageDate) / yearSeconds)
  //         );
  //       console.log((iCon * 10) / yearSeconds);
  //       setHarvestValue(() => harvestValue + (iCon * 10) / yearSeconds);
  //       setPrevHarvestValue(harvestValue);
  //     }, 10000);
  //     return () => clearInterval(harvestInterval);
  //   }
  // }, [harvestValue]);

  // console.log(value);
  return (
    <BoxStyle>
      <div className="box__header">
        <div className="box__header--logo">
          <Image fluid src={EasyIcon} alt="easy" />
          <span>EASY</span>
        </div>
        <span className="deprecated">Deprecated</span>
        <div className="box__header--status">
          <span>{interval ? `${interval} Days` : 'Open'}</span>
          <TooltipWrapper>
            <Tooltip title={tooltip1} style={{ padding: 0 }}>
              <InfoIcon style={{ fontSize: '14px' }} />
            </Tooltip>
          </TooltipWrapper>
        </div>
      </div>
      <div className="box__body">
        <div className="d-flex space-between align-flex-start apy">
          <span className="d-flex">
            <span>APY</span>
          </span>
          <div className="d-flex flex-column">
            <span>
              {apy}
              %
            </span>
          </div>
        </div>
        <div className="d-flex easyEarned">
          <span className="d-flex">
            <span>Easy Earned</span>
            &nbsp;
            <TooltipWrapper>
              <Tooltip title="Total rewards are generated and available for harvesting if the initial locking period is in the past. Withdrawal fee: 0.4%">
                <InfoIcon style={{ fontSize: '14px' }} />
              </Tooltip>
            </TooltipWrapper>
          </span>
        </div>
        <div className="d-flex space-between align-center harvest">
          <div className="harvest__values">
            <span className="harvest__values--easy">
              {harvestValue ? (
                <CountUp
                  start={prevHarvestValue}
                  end={harvestValue}
                  duration={0.75}
                  separator=" "
                  decimals={6}
                  delay={0}
                  decimal="."
                  prefix="~&nbsp;"
                >
                  {({ countUpRef }) => (
                    <div>
                      <span ref={countUpRef} />
                    </div>
                  )}
                </CountUp>
              ) : (
                0
              )}
            </span>
            <span className="harvest__values--usd">
              ~ 
              {' '}
              {withDecimals(value.harvestValue * priceUSD, 2)}
              {' '}
              USD
            </span>
          </div>
          <span
            className={
              value.enableContract
                ? `btn btn--orange ${harvestLocked && 'btn--locked'}`
                : 'btn btn--grey'
            }
            onClick={() => {
              showModal(0);
              handleBoxOpen(id);
            }}
            aria-hidden="true"
          >
            Harvest
          </span>
        </div>
        <div className="d-flex easyEarned">
          <span className="d-flex">
            <span>Easy Staked</span>
            &nbsp;
            <TooltipWrapper>
              <Tooltip title="Total EASY tokens you staked in this Pool. Withdrawal fee: 0.4%">
                <InfoIcon style={{ fontSize: '14px' }} />
              </Tooltip>
            </TooltipWrapper>
          </span>
        </div>
        <div className="">
          {value.enableContract ? (
            <div className="d-flex space-between enableContract">
              <div className="enableContract__value">
                <span className="enableContract__value--easy">
                  {withDecimals(value.stakedValue, 6)}
                </span>
                <span className="enableContract__value--usd">
                  ~ 
                  {' '}
                  {withDecimals(value.stakedValue * priceUSD, 2)}
                  {' '}
                  USD
                </span>
              </div>
              <div className="enableContract__buttons">
                <span
                  className="enableContract__buttons__button enableContract__buttons--minus"
                  onClick={() => {
                    showModal(2);
                    handleBoxOpen(id);
                  }}
                  aria-hidden="true"
                >
                  -
                </span>
                {/* <span
                  className="enableContract__buttons__button enableContract__buttons--plus btn--disable"
                  onClick={() => {
                    return null;
                    // showModal(1);
                    // handleBoxOpen(id);
                  }}
                  aria-hidden="true"
                >
                  +
                </span> */}
              </div>
            </div>
          ) : (
            // <span
            //   className="btn btn--blue btn--disable"
            //   onClick={() => {
            //     return null;
            //     // showModal(1);
            //     // handleBoxOpen(id);
            //   }}
            //   aria-hidden="true"
            // >
            //   Enter 
            //   {' '}
            //   {interval === 0 ? 'Open' : `${interval}-day`}
            //   {' '}
            //   Pool
            // </span>
            // <span
            //   className="btn btn--blue btn--disable"
            //   style={{ visibility: 'hidden' }}
            // >
            //   Enter
            // </span>
            <></>
          )}
        </div>
      </div>
      <div className="box__footer">
        <ExpansionPanel
          classes={{
            root: classes.root1,
          }}
          expanded={expandedPanel}
          onChange={handleAccordionChange}
        >
          <ExpansionPanelSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={false}
            classes={{
              root: classes.root2,
              content: classes.content2,
              expanded: classes.expanded,
            }}
          >
            <span className="btn btn--details">Details</span>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{
              root: classes.root3,
              content: classes.content3,
              // expanded: classes.expanded,
            }}
          >
            <div className="expansionPanel__header">
              <div className="expansionPanel__header--title d-flex">
                <span className="d-flex">
                  <span>Total Value Locked (TVL)</span>
                  &nbsp;
                  <TooltipWrapper>
                    <Tooltip title="Total amount of EASY tokens staked by all users in this Pool">
                      <InfoIcon style={{ fontSize: '14px' }} />
                    </Tooltip>
                  </TooltipWrapper>
                </span>
              </div>
              <p className="expansionPanel__header--subtitle">
                <strong>
                  {withDecimals(value.tvl, 6)}
                  {' '}
                  EASY
                </strong>
                <span>
                  ~
                  {' '}
                  {withDecimals(value.tvl * priceUSD, 2)}
                  {' '}
                  USD
                </span>
              </p>
            </div>
            <div className="expansionPanel__body">
              {value.actions?.length ? (
                <table className="">
                  <thead>
                    <tr>
                      <th>Amount</th>
                      <th>Time</th>
                      <th>Time until Unlocked</th>
                    </tr>
                  </thead>
                  <tbody>
                    {value.actions?.map((val) => (val?.args[0]?.int === '0' ? (
                      <tr>
                        <td>
                          {val?.args[1]?.args[0]?.args[0]?.int / 1000000}
                          {' '}
                          EASY
                        </td>
                        <td>
                          {val?.args[1] &&
                              moment
                                .unix(val?.args[1]?.args[1]?.int)
                                .format('DD/MM/YY')}
                        </td>
                        {moment
                          .unix(val?.args[1]?.args[1]?.int)
                          .add(interval, 'd')
                          .diff(moment(nowDate)) > 0 ? (
                            <td>
                              {moment
                                .unix(val?.args[1]?.args[1]?.int)
                                .add(interval, 'd')
                                .diff(moment(nowDate), 'days')}
                              {' '}
                              days
                              {' '}
                              {moment
                                .unix(val?.args[1]?.args[1]?.int)
                                .add(interval, 'd')
                                .diff(moment(nowDate), 'hours') % 24 ? (
                                  <>
                                    {moment
                                      .unix(val?.args[1]?.args[1]?.int)
                                      .add(interval, 'd')
                                      .diff(moment(nowDate), 'hours') % 24}
                                    {' '}
                                    hours
                                  </>
                                ) : null}
                            </td>
                          ) : (
                            <td>0</td>
                          )}
                      </tr>
                    ) : ['1', '2'].includes(val?.args[0]?.int) &&
                        val?.args[1]?.args[0]?.args ? (
                          <tr>
                            <td>
                              {-val?.args[1]?.args[0]?.args[0]?.int / 1000000}
                              {' '}
                              EASY
                            </td>
                            <td>
                              {val?.args[1] &&
                              moment
                                .unix(val?.args[1]?.args[1]?.int)
                                .format('DD/MM/YY')}
                            </td>
                            {/* {moment
                              .unix(val?.args[1]?.args[1]?.int)
                              .add(interval, 'd')
                              .diff(moment(nowDate)) > 0 ? (
                                <td>
                                  {moment
                                    .unix(val?.args[1]?.args[1]?.int)
                                    .add(interval, 'd')
                                    .diff(moment(nowDate), 'days')}
                                  {' '}
                                  days
                                  {' '}
                                  {moment
                                    .unix(val?.args[1]?.args[1]?.int)
                                    .add(interval, 'd')
                                    .diff(moment(nowDate), 'hours') % 24 ? (
                                      <>
                                        {moment
                                          .unix(val?.args[1]?.args[1]?.int)
                                          .add(interval, 'd')
                                          .diff(moment(nowDate), 'hours') % 24}
                                        {' '}
                                        hours
                                      </>
                                    ) : null}
                                </td>
                              ) : ( */}
                            <td>0</td>
                            {/* )} */}
                          </tr>
                      ) : null)
                    )}
                  </tbody>
                </table>
              ) : null}
            </div>
            <div className="expansionPanel__footer">
              <span
                className="btn btn--blue"
                aria-hidden="true"
                onClick={handleAccordionChange}
              >
                Close
              </span>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </BoxStyle>
  );
};

export default withStyles(styles)(Box);
