import styled from 'styled-components';

export const TopSectionWrapper = styled.div`
  background: #251a5f;
  width: 100%;

  .wrappWave {
    height: 150px;
    display: flex;
    overflow: hidden;

    @media (min-width: 768px) {
      height: 300px;
    }

    & > div {
      width: 100%;
    }

    canvas {
      width: 125% !important;
    }
  }
`;

export const ContentWrapper = styled.div`
  padding: 10vh 0;

  .wrappLogo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    img {
      max-width: 200px;
      margin-bottom: 15px;
    }

    @media (min-width: 768px) {
      margin-bottom: 0;

      img {
        max-width: 100%;
        margin-bottom: 0;
      }
    }
  }

  .carousel {
    min-height: 250px;
    @media (min-width: 576px) {
      min-height: 150px;
    }
    @media (min-width: 768px) {
      min-height: 180px;
    }
    @media (min-width: 992px) {
      min-height: 200px;
    }
    @media (min-width: 1200px) {
      min-height: 180px;
    }

    &-item {
      h1 {
        color: #00f0a9;
        font-weight: 700;
        font-size: 2em;
      }

      p {
        font-size: 1.5em;
        color: #fff;
        white-space: pre-line;
      }
    }

    &-indicators {
      bottom: 0;
      left: 0;
      right: initial;
      margin: 0;
      margin-left: 50%;
      transform: translate(-50%, 0);
      position: relative;

      [data-bs-target] {
        width: 50px;
        height: 2px;
        opacity: 1;
      }

      .active {
        background-color: #00f0a9;
      }
    }

    .fp-tableCell {
      height: 100% !important;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
    }
  }
`;

export const ProductHuntWrapper = styled.div`
  width: fit-content;
  position: relative;
  margin-top: 30px;

  @media (min-width: 767px) {
    position: absolute;
    top: 10%;
    right: 5%;
    margin-top: 0;

  }
`;

export const ProductHuntLink = styled.a`
  height: 40px;
  background: white;
  border-radius: 12px;
  padding: 8px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
