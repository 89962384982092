import React, { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { Button, Dialog } from '@material-ui/core';
import { CloseOutlined } from '@ant-design/icons';
import { Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import Left from '../../components/LeftMenu';
import RightContent from './RightContent';
import LeftContent from './LeftContent';
import { Right, InvestorPageWrapp } from './styles';
import { GeneralWrapp } from '../../components/Common/GeneralWrapp';
import TopMenu from '../../components/TopMenu';

const InvestorPage = (props) => {
  const [imgToShow, setImgToShow] = useState('');

  const [showModal, hideModal] = useModal(({ in: open, onExited }) => (
    <Dialog maxWidth="xl" fullScreen open={open} onExited={onExited} onClose={hideModal}>
      <Button onClick={hideModal} className="closeBtn"><CloseOutlined /></Button>
      <img src={imgToShow} alt="" className="modalImg" />
    </Dialog>
  ), [imgToShow]);

  const imgClickHandler = (e) => {
    showModal();
    setImgToShow(e.target.src);
  };

  return (
    <InvestorPageWrapp>
      <Helmet>
        <title>
          Tokenomics
        </title>
        <meta name="description" content="Learn more about $EASY token" />
        <link rel="canonical" href="https://divercefi.com/tokeneconomics" />

        {/* Facebook */}
        <meta property="og:title" content="Tokenomics - Learn more about $EASY token" />
        <meta property="og:description" content="Learn more about $EASY token" />
        <meta
          property="og:image"
          content="https://divercefi.com/img/logo-1200x627.png"
        />
        <meta property="og:url" content="https://divercefi.com" />

        {/* Twitter */}
        <meta name="twitter:title" content="Tokenomics - Learn more about $EASY token" />
        <meta name="twitter:description" content="Learn more about $EASY token" />
        <meta
          name="twitter:image"
          content="https://divercefi.com/img/logo-1200x627.png"
        />
        <meta name="twitter:card" content="https://divercefi.com" />
      </Helmet>

      <GeneralWrapp>
        <TopMenu fullpageApi={props.fullpageApi} logo className="mb-5" />
        <Row>
          <Left small><LeftContent /></Left>
          <Right large><RightContent imgClickHandler={imgClickHandler} /></Right>
        </Row>
      </GeneralWrapp>
    </InvestorPageWrapp>
  );
};

export default InvestorPage;
