import axios from 'axios';

export const BASEURL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

export const getCoins = () => (
  new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${BASEURL}/coins`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('something bad happened', error));
      });
  }));

export const getExchanges = () => (
  new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${BASEURL}/exchanges`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('something bad happened', error));
      });
  }));

export const getWallets = () => (
  new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${BASEURL}/wallets`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('something bad happened', error));
      });
  }));

export const getExtras = () => (
  new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${BASEURL}/extras`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('something bad happened', error));
      });
  }));

export const getDashboard = () => (
  new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${BASEURL}/dashboard`,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(new Error('something bad happened', error));
      });
  }));

export const setProfile = payload => (
  new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${BASEURL}/profile`,
      data: payload,
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(new Error('Error fetching and parsing data', error));
    });
  })
);

export const setBasket = payload => (
  new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${BASEURL}/basket`,
      data: payload,
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      reject(new Error('Error fetching and parsing data', error));
    });
  })
);
