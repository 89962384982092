import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  progress: {
    color: '#0a4fb5',
  },
});

const StyledLoading = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loading = (props) => {
  const { classes } = props;
  return (
    <StyledLoading>
      <CircularProgress className={classes.progress} />
    </StyledLoading>
  );
};

export default withStyles(styles)(Loading);
