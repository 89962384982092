import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';

import { ContentWrapper } from './styles';
import { GeneralWrapp } from '../../../../components/Common/GeneralWrapp';
import Icon1 from '../../../../assets/img/HomePage/pie1_2.png';
import Icon2 from '../../../../assets/img/HomePage/pie2_2.png';
import Icon3 from '../../../../assets/img/HomePage/pie3_2.png';
import Icon4 from '../../../../assets/img/HomePage/pie4_2.png';

const sectionsContent = [
  {
    title: 'Portfolio Planning',
    description:
      'Learn about investing and build portfolios from thousands of available assets',
    image1: Icon1,
  },
  {
    title: 'Portfolio Tracking',
    description:
      'Track manually or automatically any type of portfolio and asset class',
    image1: Icon2,
  },
  {
    title: 'Social Investing',
    description:
      'Leverage the community to copy portfolios and earn tokens based on performance',
    image1: Icon3,
    disabled: true,
  },
  {
    title: 'Integrated investing',
    description:
      'Invest across TradFi, CeFi, and DeFi platforms at lowest fees',
    image1: Icon4,
    disabled: true,
  },
];

const Content = () => {
  return (
    <ContentWrapper className="d-flex flex-column justify-content-center">
      <GeneralWrapp className="position-relative">
        <h1 className="mb-3">The Ultimate Investment Management Suite</h1>
        <h3 className="mb-5">
          Master your investor journey and manage your assets thanks to our
          portfolio solutions
        </h3>
        <Row className="justify-content-center">
          {sectionsContent.map((item) => (
            <Col
              lg={3}
              md={6}
              className="wrappElement d-flex flex-column position-relative padding-left-right mb-4"
            >
              <div className="wrappSections position-relative">
                {item.disabled ? (
                  <>
                    <div className="overlay position-absolute top-0 start-0 w-100 h-100" />
                    <span className="badge position-absolute">
                      Coming
                      <br />
                      Soon
                    </span>
                  </>
                ) : null}
                <div className="d-flex justify-content-center images mb-4">
                  <Image fluid src={item.image1} className="w-50" />
                </div>
                <h3 className="mb-4 text-center">{item.title}</h3>
                <div className="wrappDescription">
                  <p className="text-center mb-2">{item.description}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </GeneralWrapp>
    </ContentWrapper>
  );
};

export default Content;
