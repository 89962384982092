import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Image } from 'react-bootstrap';

import { EcosystemStyle } from './styles';

import Tezos from '../../../../assets/img/AboutUs/Partners/tezos.svg';
import Nomadic from '../../../../assets/img/AboutUs/Partners/nomadic-labs.svg';
import Completium from '../../../../assets/img/AboutUs/Partners/completium.svg';
import F10 from '../../../../assets/img/AboutUs/Partners/f10.svg';

const partners = [
  {
    title: 'Blockchain Technology',
    logo: Tezos,
    link: 'https://tezos.com/'
  },
  {
    title: 'Tezos ecosystem support',
    logo: Nomadic,
    link: 'https://www.nomadic-labs.com/'
  },
  // {
  //   title: 'IDO Launchpad',
  //   logo: Rocket,
  //   link: 'https://rocketlaunchpad.io/'
  // },
  {
    title: 'Blockchain development support',
    logo: Completium,
    link: 'https://completium.com/'
  },
  {
    title: 'Incubator & Accelerator',
    logo: F10,
    link: 'https://www.f10.global/'
  },
];
const Ecosystem = () => {
  return (
    <EcosystemStyle id="Ecosystem" className="mb-5">
      <h1 className="mb-5"><span className="underline underline--green">Ecosystem and Partners</span></h1>

      <Row className="justify-content-center">
        {partners.map((item) => (
          <Col md="3" sm="6" className="partners__logo">
            <div />
            <Link to={{ pathname: item.link }} target="_blank">
              <Image fluid alt="" src={item.logo} />
            </Link>
            <p>{item.title}</p>
          </Col>
        )
        )}
      </Row>
    </EcosystemStyle>
  );
};

export default Ecosystem;
