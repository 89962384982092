import styled from 'styled-components';

export const WalletInfo = styled.div`
  .account {
    &__address, &__logout {
      padding: 10px 20px;
      border: 2px solid #251A5F;
      border-radius: 20px;
      background: #fff;
      color: #251A5F;
      font-size: 16px;
      font-weight: 500;
    }
    
    &__logout {
      font-weight: 700;
      margin-left: 10px;
      cursor: pointer;
    }
  }
`;
