import React from 'react';
import { Grid } from '@material-ui/core';
import { StyledTypography } from '../../styles';

const Approach = () => {
  return (
    <Grid container spacing={2} sx={{ color: 'white', marginBottom: '50px' }}>
      <Grid item xs={12} id="approach-section1">
        <StyledTypography className="heading center">
          Asset and portfolio risk score methodology
        </StyledTypography>
        <br />
        <br />
        <StyledTypography className="title">
          Approach:
        </StyledTypography>
        <StyledTypography>
          We developed a proprietary and systematic approach to assessing the risk associated with a given portfolio. It was set up by simulating hundreds of thousands of portfolio combinations for price changes over 30, 60, and 90 days rolling time windows. Results were normalised and standardised in batches to find best best scoring scale (1-10).
        </StyledTypography>
        <br />
        <br />
        <StyledTypography className="title">
          Methodology process for actual portfolios:
        </StyledTypography>
      </Grid>
      <Grid item xs={12} id="approach-section2">
        <StyledTypography className="title">
          Data gathering
        </StyledTypography>
        <StyledTypography>
          The initial step is to organize the data pertaining to the assets comprising the portfolio. One may consider this process to be analogous to the act of arranging one&apos;s investments into a list, with each investment being assigned a specific level of importance or share. Additionally, data regarding the degree of fluctuation in the value of each investment is collected.
        </StyledTypography>
      </Grid>
      <Grid item xs={12} id="approach-section3">
        <StyledTypography className="title">
          Weighted average volatility calculation
        </StyledTypography>
        <StyledTypography>
          Subsequently, the average volatility of the portfolio is calculated by considering both the relative importance of each asset and the extent to which they fluctuate individually. This step provides a more comprehensive understanding of the overall risk profile of the portfolio.
        </StyledTypography>
      </Grid>
      <Grid item xs={12} id="approach-section4">
        <StyledTypography className="title">
          Construction of the covariance matrix
        </StyledTypography>
        <StyledTypography>
          Subsequently, a matrix designated as the covariance matrix is constructed. The matrix illustrates the interrelationships between different assets, taking into account both their individual fluctuations and their relationship to one another. This process can be likened to an understanding of how the various components of an investment portfolio fit together.
        </StyledTypography>
      </Grid>
      <Grid item xs={12} id="approach-section5">
        <StyledTypography className="title">
          Portfolio volatility definition
        </StyledTypography>
        <StyledTypography>
          Portfolio volatility is defined as the extent to which the collective value of an investment portfolio may fluctuate. This is accomplished by combining the asset importance, covariance, and asset importance again through a series of mathematical operations. This provides a precise estimate of the portfolio risk.
          <br />
          <br />
          Finally, For the sake of clarity, we round the calculated portfolio volatility to a simpler score
          facilitating the interpretation and comparison of the portfolio with other portfolios. 
          <br />
          <br />
        </StyledTypography>
      </Grid>
    </Grid>
  );
};

export default Approach;
