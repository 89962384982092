import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Row, Col, Image } from 'react-bootstrap';

import { TeamStyle } from './styles';

import Person1 from '../../../../assets/img/Investor/Person1.jpg';
import Person2 from '../../../../assets/img/Investor/Person2.jpg';
// import Person3 from '../../../../assets/img/Investor/Person3.jpeg';
// import Person4 from '../../../../assets/img/Investor/Person4.jpeg';
// import Person5 from '../../../../assets/img/Investor/Person5.jpg';
// import Person6 from '../../../../assets/img/Investor/Person6.jpg';
import Person7 from '../../../../assets/img/Investor/Person7.jpg';
// import Person8 from '../../../../assets/img/Investor/Person8.jpg';
import Person9 from '../../../../assets/img/Investor/Person9.jpeg';
// import Person10 from '../../../../assets/img/Investor/Person10.jpg';
// import Person12 from '../../../../assets/img/Investor/Person12.jpg';
// import Person13 from '../../../../assets/img/Investor/Person13.jpg';
import Person14 from '../../../../assets/img/Investor/Person14.jpeg';
import Person15 from '../../../../assets/img/Investor/Person15.jpeg';
import Riccardo from '../../../../assets/img/AboutUs/Persons/riccardo.png';
// import Cristina from '../../../../assets/img/AboutUs/Persons/cristina.png';
// import Florian from '../../../../assets/img/AboutUs/Persons/florian.jpg';
// import Pierre from '../../../../assets/img/AboutUs/Persons/pierre.jpeg';

const coreTeam = [
  {
    name: 'Henri Dethier',
    title: 'Founder & CEO',
    description: 'Ex-BCG/ Ex-Microsoft',
    link: 'https://www.linkedin.com/in/henri-dethier/',
    img: Person1,
  },
  {
    name: 'Alexandru Ciuraru',
    title: 'Head of Front-End Development',
    description: '6 years engineering and developer background',
    link: 'https://www.linkedin.com/in/alexandru-ciuraru/',
    img: Person2,
  },
  {
    name: 'Riccardo Milanesi',
    title: 'Business Development and Digital marketing expert',
    description: '4-year experienced busiess development and digital marketing expert',
    link: 'https://www.linkedin.com/in/milanesiriccardo/',
    img: Riccardo,
  },
  // {
  //   name: 'Pierre-Emmanuel Wulfman',
  //   title: 'IT Engineer',
  //   description: '',
  //   link: 'https://www.linkedin.com/in/pierreemmanuelwulfman/',
  //   img: Pierre,
  // },
];

// const extendedTeam = [
//   {
//     name: 'Jonas Fort',
//     title: 'COO & Product Manager',
//     description: 'Consultant Deloitte',
//     link: 'https://www.linkedin.com/in/jonas-fort-059712114/',
//     img: Person4,
//   },
//   {
//     name: 'Florian Fuhrmann',
//     title: 'DeFi engineer',
//     description: 'Student',
//     link: 'https://www.linkedin.com/in/florian-fuhrmann-353a60219/',
//     img: Florian,
//   },
//   {
//     name: 'Leonard Borgmann',
//     title: 'Marketing & Community Management Intern',
//     description: 'Economics Student',
//     link: 'https://www.linkedin.com/in/leonard-heinrich-borgmann-368a2620b/',
//     img: Person5,
//   },
//   {
//     name: 'Eric Vitrier',
//     title: 'Product Design Manager & Crypto Engineer',
//     description: 'Crypto and DeFi expert',
//     link: 'https://www.linkedin.com/in/eric-vitrier-bb519121b/',
//     img: Person3,
//   },
//   {
//     name: 'Cristina Estragués Condeminas ',
//     title: 'Digital Marketing & Community Mgmt. Intern',
//     description: 'Student',
//     link: 'https://www.linkedin.com/in/cristina-estragu%C3%A9s-condeminas-319539196/',
//     img: Cristina,
//   },
//   {
//     name: 'Cristian Viscreanu',
//     title: 'Web & Mobile Development Intern',
//     description: '',
//     link: 'https://www.linkedin.com/in/cristian-viscreanu-0363b212b/',
//     img: Person10,
//   },
//   {
//     name: 'Guillaume Duhamel',
//     title: 'Blockchain Engineer (Completium)',
//     description: 'CTO & Co-founder at Edukera',
//     link: 'https://www.linkedin.com/in/guillaumeduhamel/',
//     img: Person8,
//   },
// ];

const seniorAdvisors = [
  // {
  //   name: 'Frank Kirk',
  //   title: 'Crypto Advisor',
  //   description: 'Co-Founder Rocket Launchpad',
  //   link: 'https://www.linkedin.com/in/fjeffkirk/',
  //   img: Person12,
  // },
  {
    name: 'Silvan Sperl',
    title: 'Crypto Advisor',
    description: 'Research Intern at SEBA Bank',
    link: 'https://www.linkedin.com/in/silvan-sperl-b75b7814a/',
    img: Person15,
  },
  {
    name: 'Mathieu Vandenbulcke',
    title: 'Crypto Advisor',
    description: 'Blockchain Consultant Deloitte',
    link: 'https://www.linkedin.com/in/mathieu-vandenbulcke/',
    img: Person14,
  },
  {
    name: 'Christian Kunz',
    title: 'Legal Advisor',
    description: 'Lawyer at Rütimann Lawfirm',
    link: 'https://www.linkedin.com/in/christian-kunz44/',
    img: Person9,
  },
  {
    name: 'Benoit Rognier',
    title: 'CTO & Co-originator of archetype Tezos language',
    description: 'Senior Blockchain architect & developer',
    link: 'https://www.linkedin.com/in/benoitrognier/',
    img: Person7,
  },
];
const Team = () => {
  return (
    <TeamStyle id="Team">
      <h1 className="mb-5"><span className="underline underline--green">Core Team</span></h1>

      <Row className="justify-content-center mb-5">
        {coreTeam.map((item) => (
          <Col md="3" sm="6" xs="6" className="TeamPhoto mb-3">
            <div className="PhotoBox__wrapp">
              <div className="PhotoBox">
                <Image fluid className="IndividualPhoto" alt="" src={item.img} />
                <Link
                  to={{
                    pathname: item.link,
                  }}
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    style={{ fontSize: '25px' }}
                  />
                </Link>
              </div>
            </div>
            <div className="Personal">
              <h3>{item.name}</h3>
              <h4>{item.title}</h4>
              <h4>{item.description}</h4>
            </div>
          </Col>
        ))}
      </Row>
      {/* 
      <h1 className="mb-5"><span className="underline underline--green">Extended Team</span></h1>

      <Row className="justify-content-center mb-5">
        {extendedTeam.map((item) => (
          <Col md="3" sm="6" xs="6" className="TeamPhoto mb-3">
            <div className="PhotoBox__wrapp">
              <div className="PhotoBox">
                <Image fluid className="IndividualPhoto" alt="" src={item.img} />
                <Link
                  to={{
                    pathname: item.link,
                  }}
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    style={{ fontSize: '25px' }}
                  />
                </Link>
              </div>
            </div>
            <div className="Personal">
              <h3>{item.name}</h3>
              <h4>{item.title}</h4>
              <h4>{item.description}</h4>
            </div>
          </Col>
        ))}
      </Row> */}

      <h1 className="mb-5"><span className="underline underline--green">Senior Advisors</span></h1>

      <Row className="d-flex justify-content-center mb-5">
        {seniorAdvisors.map((item) => (
          <Col md="3" sm="6" xs="6" className="TeamPhoto mb-3">
            <div className="PhotoBox__wrapp">
              <div className="PhotoBox">
                <Image fluid className="IndividualPhoto" alt="" src={item.img} />
                <Link
                  to={{
                    pathname: item.link,
                  }}
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    style={{ fontSize: '25px' }}
                  />
                </Link>
              </div>
            </div>
            <div className="Personal">
              <h3>{item.name}</h3>
              <h4>{item.title}</h4>
              <h4>{item.description}</h4>
            </div>
          </Col>
        ))}
      </Row>
    </TeamStyle>
  );
};

export default Team;
