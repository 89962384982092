const initialState = '';

export const SET_COUNTRY = 'SET_COUNTRY';

export const setCountry = country => ({
  type: SET_COUNTRY,
  country,
});

/* eslint-disable no-confusing-arrow */

const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRY:
      return action.country;
    default:
      return state;
  }
};

export default countryReducer;
