import styled from 'styled-components';

export const InvestorPageWrapp = styled.div`
  background: #251A60;

  @media (min-width: 768px) {
    
  }
`;

export const Right = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: left;
  font-size: 20px;
  z-index: 1;
  padding: 0 15px;

  @media (min-width: 768px) {
    width: ${props => (props.large ? '80%' : '65%')};
    margin-left: ${props => (props.large ? '20%' : '35%')};
  }
`;

export const RightContentWrapp = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

  @media (min-width: 1920px) {
    max-width: 1400px;
  }

  h1 {
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 25px;
    background: #00F0AC;
    border-radius: 10px;
    padding: 10px;
    color: #251A5F;
  }

  h2 {
    font-weight: bold;
    text-align: center;
    font-size: 22px;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 16px;
    font-weight: bold;
  }

  h4 {
    font-size: 14px;
    font-weight: bold;
  }

  p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  a {
    font-size: 14px;
  }

  .moreInfo {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin-bottom: 0;
      margin-right: 15px;
    }

    &__btn {
      background-color: ${props => props.theme.colors.darkBlue};
      color: ${props => props.theme.colors.white};
      padding: 10px 40px;
      border-radius: 40px;
      transition: all .3s ease;

      &:hover {
        opacity: .8;
      }
    }
  }

  .mb50 {
    margin-bottom: 25px;

    &--section {
      margin-bottom: 50px;
    }
  }

  .mb70 {
    margin-bottom: 35px;
  }

  .img-responsive {
    width: 100%;
  }

  .zoomImg {
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
      opacity: 0.9;
    }
  }

  @media (min-width: 576px) {
    
  }
  
  @media (min-width: 768px) {
    h1 {
      font-size: 28px;
      margin-bottom: 25px;
    }
  
    h2 {
      font-size: 24px;
      margin-bottom: 15px;
    }

    h3 {
      font-size: 16px;
    }
    
    h3 {
      font-size: 16px;
    }
  
    p, a {
      font-size: 16px;
    }

    .mb50 {
      margin-bottom: 25px;
    }
  
    .mb70 {
      margin-bottom: 35px;
    }
  }
  
  @media (min-width: 992px) {
    
  }
  
  @media (min-width: 1200px) {
    h1 {
      font-size: 25px;
      margin-bottom: 50px;
    }
  
    h2 {
      font-size: 26px;
      margin-bottom: 30px;
    }

    h3 {
      font-size: 18px;
    }

    h4 {
      font-size: 18px;
    }

    p, a {
      font-size: 18px;
    }
    
    .mb50 {
      margin-bottom: 50px;
    }
  
    .mb70 {
      margin-bottom: 70px;
    }
  }
  
  @media (min-width: 1400px) {
    h1 {
      font-size: 36px;
    }
  
    h2 {
      font-size: 30px;
    }
  
    p, a {
      font-size: 20px;
    }
  }
`;
