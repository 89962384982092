import styled from 'styled-components';

export const PartnersWrapper = styled.div`
  background: #251a5f;
  width: 100%;
  padding: 100px 0;

  .mc__title {
    color: #fff;
    font-weight: 700;
    position: relative;
    display: inline-block;
    font-size: 24px;
    line-height: 1.6;

    @media (min-width: 768px) {
      font-size: 30px;
    }

    @media (min-width: 992px) {
      font-size: 36px;
    }
    &--underline {
      position: relative;

      &:after {
        content: '';
        background: #00f0a9;
        height: 5px;
        width: 125px;
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 100%;

        // @media (min-width: 768px) {
        //   width: 155px;
        // }

        // @media (min-width: 992px) {
        //   width: 185px;
        // }
      }
    }
  }

  .partner-grid {
    @media (max-width: 768px) {
      grid-row-gap: 40px;
    }
  }
`;

export const PartnerItem = styled.a`
  margin: auto;
  height: 100%;
  width: 75%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
