import styled from 'styled-components';

export const WrappHomePage = styled.div`
  
`;

export const Upcoming = styled.div`
  text-align: center;
  display: block;
  font-weight: bold;

  .UpcomingCoin {
    display: inline-block;
    align-content: center;
    color: #fff;
    background-color: #072e8c;
    opacity: 0.9;
    font-size: 18px;
    width: 75%;
    max-width: 250px;
    padding: 10px;

    .rocket_title {
      font-size: 35px;
      margin: 0;
    }

    p {
      margin: 0 0 10px;
    }

    img {
      max-width: 100px;
      width: 100%;
    }

    @media (max-width: 768px) {
      margin: 0 0 30px;
    }

    @media (max-width: 580px) {
      margin-top: 20px;
    }
  }
`;

export const LeftButtons = styled.div`
  text-align: center;
  display: block;
  padding: 35px 20px;

  .button {
    color: #4169e1;
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 100px;
    padding: 15px 20px;
    width: 100%;
    max-width: 250px;
    display: block;
    margin: 0 auto;
    font-size: 16px;

    &--GreyOut {
      pointer-events: none;
      opacity: 0.8;

      &:hover {
        cursor: not-allowed;
      }
    }

    &:hover,
    &:active {
      font-weight: bold;
      color: #0b4eb5;
      transition: 0.3s;
    }

    @media (max-width: 768px) {
      display: inline-block;
      font-size: 15px;
      margin-bottom: 25px;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 20px;
  }
`;

export const RightButtonsOdd = styled.div`
  padding: 30px 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;

  @media (max-width: 991.98px) {
    text-align: center;
    width: 100%;
    flex-direction: column;
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    padding: 15px 10px;
  }

  @media (max-width: 580px) {
    .smallImg {
      width: 50px;
      height: 50px;
    }
  }
`;

export const RightButtonsEven = styled.div`
  padding: 30px 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;

  .wrappImages {
    position: relative;
    width: 100%;
  }

  .bigImgLogo {
    width: 200px;
    height: auto;
  }

  @media (max-width: 991.98px) {
    text-align: center;
    width: 100%;
    flex-direction: column;
    margin-bottom: 0;
    padding: 30px 30px;

    .bigImgLogo {
      float: none;
    }
  }

  @media (max-width: 768px) {
    padding: 30px 10px;
  }

  @media (max-width: 580px) {
    .smallImg {
      width: 50px;
      height: 50px;
    }
  }
`;

export const All = styled.div`
  box-sizing: border-box;
  display: flex;
  min-height: 100vh;

  .mobile_menu {
    display: block;
    margin: 0 -10px;
  }

  .hide_mobile {
    display: none;
  }

  @media (min-width: 768px) {
    .mobile_menu {
      display: none;
    }

    .hide_mobile {
      display: block;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column-reverse;
  }
`;

export const MobileDisclaimer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    .display-sm {
      bottom: 0;
      color: #9e9e9e;
      font-size: 10px;
      margin: 20px;
      text-align: justify;
    }
  }
`;

export const Right = styled.div`
  background-color: #fff;
  width: 65%;
  margin-left: 35%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: left;
  font-size: 20px;
  z-index: 1;
  padding: 0 25px;

  p {
    margin: 0px;
    padding: 15px;
  }

  .RightContainer {
    color: #0b4eb5;
  }

  .ourSolutions {
    padding: 0 40px 0;
    font-size: 35px;
    margin: 50px 0;
    text-align: center;
  }

  h1 {
    color: #0b4eb5;
  }

  .smallImg {
    border: 2px solid #0b4eb5;
    border-radius: 100%;
    width: 60px;
    height: 60px;
    background-color: #fff;
  }

  .bigImg {
    width: 100%;
    max-width: 800px;
    border: none;
    padding: 0;
    outline: 0;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.6;
    }
  }

  .backgroundHover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: 0.3s ease;
    display: none;

    // &:hover {
    //   opacity: 1;
    // }
  }

  .plusHover {
    width: 50px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .description {
    font-size: 20px;
    padding: 20px 0;
    text-align: center;
    max-width: 800px;
  }

  .wrappImages {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1440px) {
    p {
      margin-top: 0px;
      font-size: 15px;
    }
  }

  @media (max-width: 991.98px) {
    .mobile-sm {
      display: none;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 0 10px;

    p {
      font-size: 15px;
    }

    .mobile-sm {
      display: none;
    }

    .description {
      padding: 10px;
      text-align: center;
    }

    .ourSolutions {
      padding: 0;
      margin: 30px 0 0;
      text-align: center;
    }
  }

  @media (max-width: 580px) {
    .smallImg {
      width: 50px;
      height: 50px;
    }
  }
`;

export const Left = styled.div``;

export const DisplayLogo = styled.div`
  text-align: center;
  margin: 40px;
  margin-left: 25px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  img {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
  }

  p {
    color: #0b4eb5;
    font-size: 26px;
    font-weight: 300;
    margin-top: -30px;
    padding-left: 200px;
  }

  @media (max-width: 1440px) {
    p {
      font-size: 22px;
      padding-left: 145px;
    }
  }

  @media (max-width: 1024px) {
    p {
      font-size: 18px;
      padding-left: 95px;
    }
  }

  @media (max-width: 768px) {
    text-align: center;
    height: auto;
    flex-direction: column;
    margin: 0;

    p {
      font-size: 16px;
      padding-left: 95px;
    }
  }
`;

export const OurSolution = styled.div`
  padding: 30px 40px;

  h4 {
    color: #0b4eb5;
    font-weight: 300;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    font-size: 15px;
    text-align: center;
    padding: 15px 10px;
  }
`;

export const NewDisclaimer = styled.div`
  bottom: 0;
  color: #9e9e9e;
  font-size: 10px;
  margin-left: 35px;
  margin-right: 35px;
  text-align: justify;
`;

export const SubscribeModal = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9;

  @media (min-width: 768px) {
    left: 35%;
  }
`;
