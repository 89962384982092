import React from 'react';

import { RoadmapStyle } from './styles';

import RoadmapImg from '../../../../assets/img/Investor/roadmap.png';
import EasyIcon from '../../../../assets/img/Common/logo.svg';

const Roadmap = ({ data, imgClickHandler }) => {
  return (
    <RoadmapStyle id="Roadmap" className="mb50--section">
      <h1>Roadmap</h1>
      <img alt="" src={RoadmapImg} className="fullImg img-responsive zoomImg" onClick={e => imgClickHandler(e)} aria-hidden="true" />

      <div className="roadmap__wrapp">
        {data.map(val => (
          <div className="roadmap__component" key={val.key}>
            <h5 className="roadmap__component__title">
              {val.title && <strong>{val.title}</strong>}
              <span>{val.subtitle}</span>
            </h5>
            <div className="roadmap__component__background">
              <div className="roadmap__component__list">
                {val.list.map(list => (
                  <div className="roadmap__component__item" key={list}>
                    <div className="roadmap__component__bullet">
                      <img src={EasyIcon} alt="E" />
                      {' '}
                    </div>
                    <div className="roadmap__component__text"><p>{list}</p></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </RoadmapStyle>
  );
};

export default Roadmap;
