import { Drawer, ListItem, ListItemText } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const drawerWidth = 260;

const openedMixin = (theme) => ({
  position: 'sticky',
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  position: 'sticky',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: '57px',
  [theme.breakpoints.up('sm')]: {
    width: '65px',
  },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
  },
  padding: '0, 8px',
  ...theme.mixins.toolbar,
}));

export const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  width: drawerWidth,
  top: '102px',
  height: 'calc(100vh - 134px)',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  borderRadius: 14,
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const StyledListItem = styled(ListItem)(() => ({
  display: 'block',
  '&:hover, &.active': {
    background: '#00f0ac',
  },
  '&.no-padding': {
    padding: 0,
  },
}));

export const StyledListItemText = styled(ListItemText)(({ open, size }) => ({
  color: '#251a60',
  opacity: open ? 1 : 0,

  '& .MuiListItemText-primary': {
    fontFamily: 'Montserrat',
    fontSize: size === 'small' ? 14 : 18,
    fontWeight: 600,
    whiteSpace: 'break-spaces',
  },
}));
