import styled from 'styled-components';

export const EcosystemStyle = styled.div`
  padding-top: 80px;
  
  @media (min-width: 768px) {
    padding-top: 0;
  }

  .partners {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 576px) {
      flex-direction: row;
      align-items: stretch;
      flex-wrap: no-wrap;
      justify-content: center;
    }

    &__logo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
      flex-direction: column;
      justify-content: space-between;

      @media (min-width: 768px) {
        margin-bottom: 15px;
      }

      a {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;

        @media (min-width: 576px) {
          margin-bottom: 30px;
        }
      }

      p {
        text-align: center;
        font-size: 16px;
        margin-bottom: 15px;
        color: #fff;
        
        @media (min-width: 576px) {
          margin-bottom: 0;
        }
      }

      img {
        width: 100%;
        max-width: 200px;
      }
    }
  }
`;
