import { getWallets } from '../lib/api';

const initialState = {
  isFetching: true,
  fetched: false,
  list: [],
};

export const WALLETS_LOAD = 'WALLETS_LOAD';
export const TOGGLE_FETCHING_WALLETS = 'TOGGLE_FETCHING_WALLETS';
export const TOGGLE_FETCHED_WALLETS = 'TOGGLE_FETCHED_WALLETS';
export const TOGGLE_WALLET = 'TOGGLE_WALLET';

export const loadWallets = data => ({ type: WALLETS_LOAD, payload: data });
export const toggleFetching = value => ({ type: TOGGLE_FETCHING_WALLETS, value });
export const toggleFetched = value => ({ type: TOGGLE_FETCHED_WALLETS, value });
export const toggleWallet = id => ({ type: TOGGLE_WALLET, id });

export const fetchWallets = () => {
  return async (dispatch) => {
    try {
      dispatch(toggleFetching(true));
      const wallets = await getWallets();
      dispatch(loadWallets(wallets));
      dispatch(toggleFetching(false));
      dispatch(toggleFetched(true));
      return wallets;
    } catch (error) {
      return Error('Error fetching and parsing data', error);
    }
  };
};

/* eslint-disable no-confusing-arrow */

const walletsReducer = (state = initialState, action) => {
  switch (action.type) {
    case WALLETS_LOAD:
      return { ...state, list: action.payload };
    case TOGGLE_FETCHING_WALLETS:
      return { ...state, isFetching: action.value };
    case TOGGLE_FETCHED_WALLETS:
      return { ...state, fetched: action.value };
    case TOGGLE_WALLET:
      return { ...state, list: state.list.map(value => (value.id === action.id) ? { ...value, selected: !value.selected } : value) };
    default:
      return state;
  }
};

export default walletsReducer;
