import React from 'react';

import { ProjectionsStyle, TableBreak } from './styles';

import ProjectionsGraph from '../../../../assets/img/Investor/projections-graph.png';
import ProjectionsTable from '../../../../assets/img/Investor/projections-table.png';

const Projections = ({ data, imgClickHandler }) => {
  return (
    <ProjectionsStyle id="Projections" className="mb50--section">
      <h1>Projected Supply</h1>
      <img alt="" src={ProjectionsGraph} className="img-responsive zoomImg" onClick={e => imgClickHandler(e)} aria-hidden="true" />

      <div className="projectionsTable d-none d-md-block">
        <table className="header">
          <thead>
            <tr>
              <th width="40%">Market cap projections for EASY</th>
              <th width="12%">Real Scenario</th>
              <th width="12%">Scenario 1</th>
              <th width="12%">Scenario 2</th>
              <th width="12%">Scenario 3</th>
              <th width="12%">Scenario 4</th>
            </tr>
          </thead>
        </table>

        {data.map((val) => {
          return val ? (
            <table key={val.id}>
              <tbody>
                <tr>
                  <td width="40%">
                    <strong>{val[0]}</strong>
                  </td>
                  <td width="12%">{val[1]}</td>
                  <td width="12%">{val[2]}</td>
                  <td width="12%">{val[3]}</td>
                  <td width="12%">{val[4]}</td>
                  <td width="12%">{val[5]}</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <TableBreak />
          );
        })}
      </div>
      <div className="d-block d-md-none">
        <img alt="" src={ProjectionsTable} className="img-responsive zoomImg" onClick={e => imgClickHandler(e)} aria-hidden="true" />
      </div>
    </ProjectionsStyle>
  );
};

export default Projections;
