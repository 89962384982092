import styled from 'styled-components';

export const ProjectionsStyle = styled.div`
  img {
    margin-bottom: 30px;
  }

  .projectionsTable {
    overflow-x: scroll;
    
    @media (min-width: 576px) {
      overflow-x: hidden;
    }

    table {
      width: 100%;
      min-width: 500px;
      margin-bottom: 25px;
      background: #f4f4f4;
      border-radius: 15px;
      margin-bottom: 10px;
      font-size: 12px;

      @media (min-width: 576px) {
        font-size: 14px;
      }

      @media (min-width: 1200px) {
        font-size: 16px;
      }

      &.header {
        background: #00F0AC;
      }
    }

    td,
    th {
      padding: 15px;
      color: ${props => props.theme.colors.black2};
      text-align: center;

      &:first-of-type {
        text-align: left;
      }
    }
  }
`;

export const TableBreak = styled.div`
  width: 100%;
  min-width: 500px;
  border-bottom: 4px solid #00F0AC;
  margin-bottom: 10px;
`;
