import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, useMediaQuery } from '@material-ui/core';
import { styled, useTheme } from '@material-ui/core/styles';
import TermsConditions from './components/TermsConditions';
import Disclaimer from './components/Disclaimer';
import CookiePolicy from './components/CookiePolicy';
import SecuritySidebar from './components/SecuritySidebar';

import TopMenu from '../../components/TopMenu';
import FixedMenuWrapper from '../../components/FixedMenuWrapper';
import { GeneralWrapp } from '../../components/Common/GeneralWrapp';

export const Wrapper = styled(Box)(() => ({
  minHeight: '100vh',
  paddingTop: '70px',
  background: '#251a5f',
}));

export const StyledContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  minHeight: 'calc(100vh - 70px)',
  maxWidth: '1440px',
  margin: '0px auto',
  padding: 24,
  display: 'flex',
  gap: 32,

  [theme.breakpoints.down('sm')]: {
    padding: 12,
  },
}));

export const BodyWrapper = styled(Box)(() => ({
  width: '100%',
}));

const Security = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
      <Helmet>
        <title>Divercefi - Your ultimate investment management solution</title>
        <meta
          name="description"
          content="Discover investment opportunities, follow best strategies, build diversified portfolios, keep track of- and manage all your investments, leverage DeFi"
        />
        <link rel="canonical" href="https://divercefi.com/" />

        {/* Facebook */}
        <meta
          property="og:title"
          content="Divercefi - Your ultimate investment management solution"
        />
        <meta
          property="og:description"
          content="Discover investment opportunities, follow best strategies, build diversified portfolios, keep track of- and manage all your investments, leverage DeFi"
        />
        <meta
          property="og:image"
          content="https://divercefi.com/img/logo-1200x627.png"
        />
        <meta property="og:url" content="https://divercefi.com" />

        {/* Twitter */}
        <meta
          name="twitter:title"
          content="Divercefi - Your ultimate investment management solution"
        />
        <meta
          name="twitter:description"
          content="Discover investment opportunities, follow best strategies, build diversified portfolios, keep track of- and manage all your investments, leverage DeFi"
        />
        <meta
          name="twitter:image"
          content="https://divercefi.com/img/logo-1200x627.png"
        />
        <meta name="twitter:card" content="https://divercefi.com" />
      </Helmet>

      <div>
        <FixedMenuWrapper>
          <GeneralWrapp className="flex-grow-1">
            <TopMenu />
          </GeneralWrapp>
        </FixedMenuWrapper>
        <Box>
          <Wrapper>
            <StyledContainer>
              {!isMobile && <SecuritySidebar />}
              <Container maxWidth="lg" sx={{ paddingTop: 8, paddingBottom: 8 }}>
                <TermsConditions />
                <Disclaimer />
                <CookiePolicy />
              </Container>
            </StyledContainer>
          </Wrapper>
        </Box>
      </div>
    </div>
  );
};

export default Security;
