import React from 'react';

import { HeaderStyle } from './styles';
import WalletButton from '../WalletButton';

const Header = () => {
  return (
    <HeaderStyle>
      <div className="title d-flex flex-column">
        <h1 className="d-flex justify-content-center align-items-center">
          Divercefi DeFi
          {' '}
          <div className="d-flex flex-column justify-content-center">
            <span className="testVersion testVersion--deprecated">Deprecated ⚠️</span>
          </div>
        </h1>
      </div>
      <WalletButton />
    </HeaderStyle>
  );
};

export default Header;
