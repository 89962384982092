import React from 'react';
import { Helmet } from 'react-helmet';

import { AboutUsWrapp } from './styles';
import { GeneralWrapp } from '../../components/Common/GeneralWrapp';
import TopMenu from '../../components/TopMenu';
import Ecosystem from './componenets/Ecosystem';
import Team from './componenets/Team';
import Footer from '../../components/Footer';

const AboutUs = (props) => {
  return (
    <AboutUsWrapp>
      <Helmet>
        <title>
          About us
        </title>
        <meta name="description" content="Learn more about our partners and our team to make Crypto accessible to everyone" />
        <link rel="canonical" href="https://divercefi.com/about-us" />

        {/* Facebook */}
        <meta property="og:title" content="Divercefi - Learn more about our partners and our team to make Crypto accessible to everyone" />
        <meta property="og:description" content="Learn more about our partners and our team to make Crypto accessible to everyone" />
        <meta
          property="og:image"
          content="https://divercefi.com/img/logo-1200x627.png"
        />
        <meta property="og:url" content="https://divercefi.com" />

        {/* Twitter */}
        <meta name="twitter:title" content="Divercefi - Learn more about our partners and our team to make Crypto accessible to everyone" />
        <meta name="twitter:description" content="Learn more about our partners and our team to make Crypto accessible to everyone" />
        <meta
          name="twitter:image"
          content="https://divercefi.com/img/logo-1200x627.png"
        />
        <meta name="twitter:card" content="https://divercefi.com" />
      </Helmet>

      <GeneralWrapp>
        <TopMenu fullpageApi={props.fullpageApi} logo className="mb-7" />
        <Ecosystem />
        <Team />
      </GeneralWrapp>
      <Footer />
    </AboutUsWrapp>
  );
};

export default AboutUs;
