import React from 'react';
import styled, { injectGlobal, ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import CookieConsent from 'react-cookie-consent';

import Routes from './Routes';

import createStore from './state/createStore';

const theme = {
  colors: {
    white: '#FFFFFF',
    blue: '#166FFA',
    darkBlue: '#0B4EB5',
    lightBlue: '#F6F7FE',
    darkGrey: '#8492A8',
    lightGrey: '#D1DAE9',
    black: '#41424B',
    black2: '#1D1D1B',
  },
};

/* eslint-disable no-unused-expressions */
injectGlobal`
  body {
    background: #F6F7FE;
    margin: 0;
    padding: 0;
    color: #41424B;
  }
  .ant-tooltip-inner {
    background: white !important;
    color: blue !important;
    font-size: 12px !important;
    color: #251a5f !important;
    text-align: center !important;
    line-height: 18px !important;
    padding: 10px 15px !important;
  }

  .ant-tooltip-placement-top .ant-tooltip-arrow, .ant-tooltip-placement-topLeft .ant-tooltip-arrow, .ant-tooltip-placement-topRight .ant-tooltip-arrow {
    border-top-color: white !important;
  }
`;

const Wrapper = styled.div`
  background: ${theme.colors.lightBlue};
`;

const store = createStore();

const App = () => {
  return (
    <Provider store={store}>
      <Wrapper>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="divercefiCookiePolicy"
          style={{ color: '#251a5f', background: '#fff', borderTop: '1px solid #fff' }}
          buttonStyle={{ color: '#fff', background: '#251a5f', fontSize: '14px', borderRadius: '25px', padding: '10px 20px' }}
          expires={999}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      </Wrapper>
    </Provider>
  );
};

export default App;
